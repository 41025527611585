import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Row, Col } from 'react-flexbox-grid'
import { toast } from 'react-toastify'
import Add from '@components/Icons/Add'
import Remove from '@components/Icons/Remove'
import CartDropdown from '@components/MobileCartDropdown'
import { Divider, ProductInfo, ProductName, ProductAction, Label } from './styles'
import { Creators } from '../../actions'
import { floatToBRL } from '../../helpers/MoneyFormat'
import { minutesToTime } from '../../helpers/DateConversion'
import { isLogged } from '../../redux/LoginRedux'
import GAEvents from '../../helpers/GAEvents'

class MobileCart extends Component {
  static propTypes = {
    buttonText: PropTypes.string,
    mobileVisible: PropTypes.bool,
    fixed: PropTypes.bool,
    disabled: PropTypes.bool,
    dropdown: PropTypes.bool,
    width: PropTypes.number,
    link: PropTypes.string,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    onUpdate: PropTypes.func
  }

  static defaultProps = {
    buttonText: 'Escolher forma de pagamento',
    mobileVisible: false,
    fixed: false,
    disabled: false,
    dropdown: true,
    width: 0,
    link: '',
    onClick: () => {},
    onClose: () => {},
    onUpdate: () => {},
  }

  constructor(props) {
    super(props)

    this.cartRemoteFetched = false

    this.state = {
      updated: false,
      visible: props.mobileVisible,
      freeDelivery: false,
      productsCount: 0,
      containerClass: '',
      installmentOnCreditCard: [],
      installmentOnCreditCardSelect: {
        quantity: '',
        totalAmount: ''
      },
      selectAmount: '',

      redirectTo: null,
      isDeliveryFree: false
    }
  }

  componentDidMount() {
    const { products, loggedIn, attemptGetCartRemote } = this.props

    // Get remote cart only if we are logged
    if (loggedIn) {
      this.cartRemoteFetched = true
      attemptGetCartRemote()
    }

    if (products && products.length > 0) {
      this._handleFreeDelivery(products)
      this._updateProductsCount(products)
    }
  }

  componentWillReceiveProps(newProps) {
    const { attemptUpdateCartRemote, attemptGetCartRemote, onUpdate, attemptOrderInstallment } = this.props
    const { products, loggedIn } = newProps

    if (products.length <= 0) {
      attemptOrderInstallment(null);
    }

    // Update remote cart once we are logged
    if (loggedIn && !this.cartRemoteFetched) {
      this.cartRemoteFetched = true

      if (products.length > 0) {
        attemptUpdateCartRemote(products)
      } else {
        attemptGetCartRemote()
      }
    }

    // Fire update event
    onUpdate(products)

    if (products && products.length > 0) {
      this._handleFreeDelivery(products)

      const changed = this._updateProductsCount(products)
      if (changed) {
        // Update remote products
        if (loggedIn && this.cartRemoteFetched) {
          attemptUpdateCartRemote(products)
        }

        this.animateCart()
      }
    }
  }

  /**
   * Animate cart dropdown button
   */
  animateCart = () => {
    const { containerClass } = this.state

    if (containerClass.length === 0) {
      this.setState({
        containerClass: 'jello-horizontal'
      }, () => {
        setTimeout(() => {
          this.setState({
            containerClass: ''
          })
        }, 900)
      })
    }
  }

  _updateProductsCount = (products) => {
    const { productsCount } = this.state

    let count = 0
    products.forEach(({ quantity }) => {
      count += quantity
    })

    this.setState({
      productsCount: count
    })

    return count > productsCount
  }

  /*toggleDropwdown = () => {
    // Native mobile app
    const isMyPharmaBase = window.navigator ? window.navigator.userAgent === 'mypharmaBase' : false

    if (!isMyPharmaBase) {
      this.setState(state => ({ visible: !state.visible }))
    } else {
      this.setState({
        redirectTo: {
          path: '/carrinho',
          push: true
        }
      })
    }
  }

  closeDropdown = () => {
    // Native mobile app
    const isMyPharmaBase = window.navigator ? window.navigator.userAgent === 'mypharmaBase' : false

    if (!isMyPharmaBase) {
      const { onClose } = this.props
      this.setState(state => ({ visible: false }), () => {
        onClose()
      })
    } else {
      this.setState({
        redirectTo: {
          path: '/produtos',
          push: false
        }
      })
    }
  }*/
  _verifyDeliveryFree = () => {
    const { deliveries, products } = this.props;
    const { isDeliveryFree } = this.state;
    let total = 0;
  
    if (products.length > 0 && deliveries.length > 0) {
      const { free_from } = deliveries[0]
      const deliveryFreeFrom = Number(free_from)

      products.forEach(product => {
        const { price, quantity } = product

        total += (quantity * Number(price.toFixed(2)))
      })
   
      if (deliveryFreeFrom > 0 && total > deliveryFreeFrom && !isDeliveryFree) {    
        toast.success('Você conseguiu entrega grátis!', {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false
        })
      }
    }
  }

  _handleAddProduct = (id) => {
    const { products, loggedIn, setCartProducts, attemptUpdateCartRemote } = this.props

    const index = products.findIndex(p => p.id === id)
    if (index >= 0) {
      // Check if product has available stock!!!
      if (products[index].quantity < products[index].maxQuantity) {
        products[index].quantity += 1
      } else {
        toast.info('Desculpe, produto não tem mais estoque!', {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false
        })
      }

      setCartProducts(products)

      // Update remote cart only if we are logged
      if (loggedIn) {
        attemptUpdateCartRemote(products)
      }
      this.setState({
        updated: true
      }, () => {
        this._handleFreeDelivery(products)
      })
      this._verifyDeliveryFree()
    }
  }

  _handleRemoveProduct = (id) => {
    const { products, loggedIn, setCartProducts, attemptUpdateCartRemote } = this.props

    const index = products.findIndex(p => p.id === id)
    if (index >= 0) {
      if (products[index].quantity <= 1) {
        products.splice(index, 1)
      } else {
        products[index].quantity -= 1
      }

      setCartProducts(products)

      // Update remote cart only if we are logged
      if (loggedIn) {
        attemptUpdateCartRemote(products)
      }
      this.setState({
        updated: true
      }, () => {
        this._handleFreeDelivery(products)
      })
    }
  }

  _handleFreeDelivery = (products) => {
    const { deliveries, currentAddress } = this.props
    const { freeDelivery } = this.state
    const { not_deliverable } = currentAddress ? currentAddress : false

    // Handle free delivery
    let total = 0
    let _freeDelivery = false

    products.forEach(product => {
      const { price, quantity } = product

      total += (quantity * price)
    })

    if (deliveries && deliveries.length > 0 && !not_deliverable) {
      const { free_from, delivery_fee } = deliveries[0]
      const deliveryFreeFrom = Number(free_from)
      const deliveryFee = Number(delivery_fee)

      _freeDelivery = (deliveryFreeFrom > 0 && total >= deliveryFreeFrom && deliveryFee > 0 ? true : false)
    }

    if (freeDelivery !== _freeDelivery) {

      this.setState({
        freeDelivery: _freeDelivery,
        isDeliveryFree: _freeDelivery
      })
    }
  }

  _handleButtonClick = (ev) => {
    const { onClick } = this.props

    this.setState({
      visible: false
    }, () => {
      onClick(ev)
    })
  }

  _renderProducts = () => {
    const { products } = this.props

    if (products) {
      return products.map((product, index) => {
        const { id, model, quantity, maxQuantity, price } = product
        const total = price * quantity
        const addDisabled = quantity >= maxQuantity

        return (
          <Row key={index}>
            <Col xs={12}>
              <Row>
                <ProductName>{model}</ProductName>
                <ProductAction>
                  <Remove onClick={() => this._handleRemoveProduct(id)} />
                </ProductAction>
                <ProductAction>{quantity}</ProductAction>
                <ProductAction>
                  <Add width={30} height={30} onClick={() => this._handleAddProduct(id)} disabled={addDisabled} />
                </ProductAction>
              </Row>
              <Row>
                <ProductInfo>{quantity} unidades</ProductInfo>
                <ProductInfo end="1" >{floatToBRL(price)}</ProductInfo>
              </Row>
              <Row>
                <ProductInfo>Total</ProductInfo>
                <ProductInfo end="1" total>{floatToBRL(total)}</ProductInfo>
              </Row>
            </Col>
          </Row>
        )
      })
    }
  }

  _renderResume = () => {
    const { products, deliveries, loggedIn, currentAddress,  orderInstallment, attemptOrderInstallment } = this.props
    const { not_deliverable } = currentAddress ? currentAddress : false

    let fee = 0
    let total = 0
    let deliveryTime = '0 - 0 min'
    let totalWithInstallment = 0

    if (products) {
      products.forEach(product => {
        const { price, quantity } = product

        total += (quantity * Number(price.toFixed(2)))
      })
    }

    if (deliveries && deliveries.length > 0 && !not_deliverable) {
      deliveries.sort(function(a, b) {
        return a.delivery_fee - b.delivery_fee 
      })
      const { delivery_fee, free_from, delivery_time } = deliveries[0]
      const deliveryFee = Number(delivery_fee)
      const deliveryFreeFrom = Number(free_from)

      if (total < deliveryFreeFrom || deliveryFreeFrom === 0) {
        fee = deliveryFee
      }

      // Calculate delivery time
      const { value, suffix } = minutesToTime(delivery_time)
      const offset = suffix === 'mins' ? 20 : 1

      deliveryTime = `${value} - ${value + offset} ${suffix}`
    }

    if (orderInstallment > Number(total + fee).toFixed(2)) {
      totalWithInstallment = orderInstallment;
  } else {
    attemptOrderInstallment(null)
  }

    return (
      <Row style={{marginTop: 10}}>
        <Col xs={12}>
          <Row>
            <ProductInfo>{loggedIn && currentAddress ? 'Taxa de Entrega ' : 'Taxa de Entrega a partir de'}</ProductInfo>
            <ProductInfo end="1" >
              {
                floatToBRL(fee)
              }
            </ProductInfo>
          </Row>
          {
            loggedIn && currentAddress ? (
              <Row>
                <ProductInfo>Tempo de Entrega</ProductInfo>
                <ProductInfo end="1" >{deliveryTime}</ProductInfo>
              </Row>
            ) : null
          }
          {
            total > 0 ? (
              <Row>
                <ProductInfo>Total do pedido</ProductInfo>
                <ProductInfo end="1" total>{floatToBRL(total + fee)}</ProductInfo>
              </Row>
            ) : null
          }
          { totalWithInstallment ? 
            <Row>
              <ProductInfo>Total parcelado</ProductInfo>
              <ProductInfo end="1">{floatToBRL(totalWithInstallment)}</ProductInfo>
            </Row>
          : null}
        </Col>
      </Row>
    )
  }

  _renderFreeShipping = () => {
    const { products, deliveries, currentAddress } = this.props
    const { freeDelivery } = this.state
    const { not_deliverable } = currentAddress ? currentAddress : false


    let total = 0
    let missingForFree = 0

    if (products) {
      products.forEach(product => {
        const { price, quantity } = product

        total += (quantity * price)
      })
    }

    if (deliveries && deliveries.length > 0 && !not_deliverable) {
      const { free_from } = deliveries[0]
      const deliveryFreeFrom = Number(free_from)

      if (total < deliveryFreeFrom) {
        missingForFree = deliveryFreeFrom - total
      }
    }

    return (
      <Row style={{borderTop: '1px solid #A8A8A8'}}>
        <Col xs={12}>
          <Row style={{display: 'flex', justifyContent: 'center'}}>
            {
              !freeDelivery && !not_deliverable ? (
                <Label>
                  Para alcançar entrega grátis você precisa de mais: <br />
                  <b>{floatToBRL(missingForFree)}</b> em compras!
                </Label>
              ) : (
                <Label bold>VOCÊ CONSEGUIU ENTREGA GRÁTIS!</Label>
              )
            }
          </Row>
        </Col>
      </Row>
    )
  }

  render() {
    const { link, buttonText, products, deliveries, store, loggedIn, disabled, dropdown, setCartProceed, onClose, currentAddress, setCartProceedBySocialLogin, setCartProceedHome } = this.props
    const { config } = store
    const { config_navbar_color } = config
    const { delivery_fee, minimum_value, free_from } = deliveries[0]
    const { containerClass, redirectTo, visible } = this.state
    const {  not_deliverable} = currentAddress ? currentAddress : false

    const deliveryFree = Number(delivery_fee)
    const deliveryFreeFrom = Number(free_from)
    const hasProducts = products && products.length > 0

    let productsQuantity = 0
    let productsTotalValue = 0

    if (products) {
      products.forEach(prod => {
        const { price, quantity } = prod

        productsQuantity += quantity
        productsTotalValue += (quantity * price)
      })
    }

    let cartLink = {
      pathname: link.length > 0 ? link : '/checkout'
    }

    let handleClick = () => {}

    if (!loggedIn) {
      cartLink = {}
      handleClick = () => {
        setCartProceed(true)
        setCartProceedBySocialLogin(true);
        setCartProceedHome(false);
      }
    }
    else {
      if (window.location.pathname !== '/checkout') {
        cartLink.pathname = '/endereco'
        cartLink.state = {
          selectAddress: true
        }
      }
    }

    /**
     * Cart in dropdown
     */
    if (dropdown) {
      return (
        <CartDropdown
          productsQuantity={productsQuantity}
          productsTotalValue={productsTotalValue}
          minimumValue={Number(minimum_value)}

          navbarColor={config_navbar_color}
          buttonText={buttonText}
          containerClass={containerClass}
          
          cartLink={cartLink}

          disabled={disabled}
          hasProducts={hasProducts}
          visible={visible}

          onClick={()=> {
            handleClick() || this._handleButtonClick()
            GAEvents({ category: 'Shop', action: `Clique no botão ${buttonText}`,label : `MobileCart` })
          }}
          onClose={onClose}
          onToggle={(toggle) => this.setState({ visible: toggle })}
          onNativeToggle={(payload) => this.props.history.push(payload.path)}

          deliverable={not_deliverable}
        >
          <div style={{height: '400px', overflowY: 'auto', overflowX: 'hidden'}}>
            {this._renderProducts()}
          </div>
          {this._renderResume()}
          {
            deliveryFree > 0 && deliveryFreeFrom > 0  && !not_deliverable ? this._renderFreeShipping() : null
          }

          {
            // Redirect to cart route when we are in native app
            redirectTo ? <Redirect to={redirectTo.path} push={redirectTo.push || false} /> : null
          }
        </CartDropdown>
      )
    }


    /**
     * We can render our cart without dropdown here
     */
    return (
      <div style={{marginBottom: 30}}>
        {this._renderProducts()}

        <Divider />

        {this._renderResume()}
      </div>
    )
  }
}

const mapStateToProps = ({ login, address, cart, startup, delivery, order }) => ({
  cartFetching: cart.fetching,
  loggedIn: isLogged(login),
  currentAddress: address.currentAddress,
  products: cart.products,
  store: startup.store,
  deliveries: delivery.deliveries,
  deliveryFetching: delivery.fetching,
  orderInstallment: order.orderInstallment

})

const mapDispatchToProps = dispatch => ({
  setCartProducts: (products) => dispatch(Creators.cartSetProductRequest(products)),
  getCartProducts: () => dispatch(Creators.cartListProductRequest()),
  setCartProceed: (proceed) => dispatch(Creators.cartSetProceed(proceed)),
  attemptGetCartRemote: () => dispatch(Creators.cartGetRemoteRequest()),
  attemptUpdateCartRemote: (products) => dispatch(Creators.cartUpdateRemoteRequest(products)),
  attemptGetDeliveries: ({ city_id }) => dispatch(Creators.deliveryListRequest(city_id)),
  attemptOrderInstallment: (orderInstallment) => dispatch(Creators.orderInstallmentRequest(orderInstallment)),

  setCartProceedBySocialLogin: (proceedCheckout) => dispatch(Creators.cartProceedBySocialLogin(proceedCheckout)),
  setCartProceedHome: (proceedHome) => dispatch(Creators.cartProceedHome(proceedHome))

})

export default connect(mapStateToProps, mapDispatchToProps)(MobileCart)

