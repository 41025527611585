import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TrashAltIcon from '@components/Icons/TrashAlt'
import DeliveryBoy from '@components/Icons/DeliveryBoy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { AddressCardContainer, FakeAddressCardContainer, InfoContainer, ActionContainer, AddressPrimary, AddressSecondary, ActionDivider } from './styles'
import './index.css'

export default class AddressCard extends Component {
  static propTypes = {
    slideIn: PropTypes.bool,
    slideInOffet: PropTypes.number,
    id: PropTypes.number,
    street: PropTypes.string,
    number: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    uf: PropTypes.string,
    selected: PropTypes.bool,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func,
  }

  static defaultProps = {
    slideIn: false,
    slideInOffet: 1,
    selected: false,
    onDelete: () => {},
    onSelect: () => {}
  }

  constructor(props) {
    super(props)

    this.state = {
      hide: false,
      deleted: false,
      className: 'animated slideInLeft'
    }
  }

  componentWillMount() {
    const { slideIn } = this.props

    this.setState({
      hide: slideIn
    })
  }

  componentDidMount() {
    const { slideInOffet } = this.props
    const { hide } = this.state

    if (hide) {
      setTimeout(() => {
        this.setState({
          hide: false
        })
      }, (slideInOffet * 500))
    }
  }

  _handleOnDelete = (id) => {
    const { onDelete } = this.props

    this.setState({
      className: 'animated slideOutRight'
    })

    setTimeout(() => {
      onDelete(id)

      // Show invisible fake card
      this.setState({
        hide: true
      })

      setTimeout(() => {
        // Make invisible fake card disappear
        this.setState({
          deleted: true
        })
      }, 100)
    }, 500)
  }

  render() {
    const {
      id,
      street,
      neighborhood,
      city,
      uf,
      selected,
      onSelect,
    } = this.props
    const { hide, deleted, className } = this.state

    return (
      !hide ? (
        <AddressCardContainer className={className}>
          <button style={{display: "flex", flexDirection: "row", alignItems: "center" ,background: "none", outline: 0, border: 0, padding:0, margin:0}} onClick={() => onSelect(id)}>
            <div>
             <DeliveryBoy width="30" height="30"/>
            </div>
            <InfoContainer>
             <AddressPrimary>
                {street}
              </AddressPrimary>
              <AddressSecondary>
                {neighborhood} - {city}, {uf}
              </AddressSecondary>
            </InfoContainer>
          </button>

          <ActionContainer>
            <FontAwesomeIcon icon={Icons.faCheckCircle} size="2x" color={selected ? '#00BF91' : ''} style={{marginRight: 10}} onClick={() => onSelect(id)} />
          <ActionDivider />
            <TrashAltIcon width={20} height={25} style={{cursor: 'pointer', marginLeft: 10}} onClick={() => this._handleOnDelete(id)} />
          </ActionContainer>
        </AddressCardContainer>
      ) : <FakeAddressCardContainer className={deleted ? 'address-card-disappear' : ''} />
    )
  }
}
