import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import LaddaButton, { XL } from '@zumper/react-ladda'
import Input from '@components/Input'
import { Row, Col } from 'react-flexbox-grid'
import { RegisterAddressContainer, Title, RegisterAddressContent } from './styles'
import { Creators } from '../../actions'
import Autocomplete from '../Autocomplete'

class RegisterAddress extends Component {
  static propTypes = {
    goToCheckout: PropTypes.bool,
    onMinimumValueFail: PropTypes.func
  }

  static defaultProps = {
    goToCheckout: false,
    onMinimumValueFail: () => { }
  }

  constructor(props) {
    super(props)

    this.state = {
      canSave: false,
      saveLoading: false,
      success: false,
      checkingDelivery: false,
      loading: true,
      selectNeighborhood: false,
      cities: [],
      neighborhoods: [],
      inputs: {
        addressCity: '',
        addressNeighborhood: '',
        addressStreet: '',
        addressNumber: '',
        addressComplement: ''
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const { setCurrentAddress, attemptGetDeliveries, goToCheckout, cartProducts } = this.props
    const { _address, _delivery } = newProps
    const { saveLoading, loading, checkingDelivery } = this.state

    // Copy current state
    let newState = Object.assign({}, this.state)

    if (saveLoading && !_address.fetching && _address.address !== null) {
      const { address_id, street, complement, neighborhood } = _address.address

      if (goToCheckout) {
        setCurrentAddress({
          id: address_id,
          number: 'S/N',
          street,
          complement,
          neighborhood
        })

        attemptGetDeliveries(neighborhood.neighborhood_id)

        newState.checkingDelivery = true
      } else {
        newState.success = true
      }

      // We have save :P
      newState.saveLoading = false
    }

    // Check if address delivery fee is valid
    if (!_delivery.fetching && _delivery.deliveries !== null && cartProducts && checkingDelivery) {
      if (_delivery.deliveries.length > 0) {
        const { minimum_value } = _delivery.deliveries[0]
        let totalCartValue = 0

        cartProducts.forEach(product => {
          const { price, quantity } = product
          totalCartValue += (quantity * price)
        })

        // Check if cart total is below delivery minimum value
        if (Number(totalCartValue) < Number(minimum_value)) {
          this.props.onMinimumValueFail({
            totalCartValue: Number(totalCartValue),
            minimumValue: Number(minimum_value)
          })
        } else {
          newState.success = true
        }
      }
    }

    // Loaded cities
    if (!_delivery.fetching && _delivery.cities !== null && loading) {
      newState = {
        ...newState,
        loading: false,
        selectNeighborhood: true,
        cities: _delivery.cities
      }
    }

    // Update state if needed
    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  onChangeField = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      inputs: {
        ...state.inputs,
        // Clear neighborhood input if a different city was selected
        addressNeighborhood: (name === 'addressCity' && value !== state.inputs['addressCity']) ? '' : state.inputs['addressNeighborhood'],
        // Define new value for input
        [name]: value
      }
    }), () => {
      // Validate register fields
      if (name === 'addressCity') {
        this._renderNeighborhoods()
      }
      this.validateFields()
    })
  }

  validateFields = () => {
    const { inputs } = this.state
    const { addressCity, addressNeighborhood, addressStreet } = inputs
    let canSave = false

    if (addressCity && addressNeighborhood && addressStreet.length > 0) {
      canSave = true
    }

    if (canSave !== this.state.canSave) {
      this.setState({
        canSave
      })
    }
  }

  _handleSave = () => {
    const { attemptRegisterAddress, _user } = this.props
    const { cities, inputs } = this.state
    const { addressCity, addressNeighborhood, addressStreet, addressComplement } = inputs
    const { firstname, lastname } = _user

    const city = cities.find(p => p.city_id === Number(addressCity))
    if (city) {
      const neighborhood = city.neighborhoods.find(p => p.neighborhood_id === Number(addressNeighborhood))

      if (neighborhood) {
        this.setState({
          saveLoading: true
        }, () => {
          attemptRegisterAddress({
            firstname,
            lastname,
            street: addressStreet,
            neighborhood_id: neighborhood.neighborhood_id,
            complement: addressComplement
          })
        })
      }
    }
  }

  _renderCities = () => {
    const { cities } = this.state

    if (cities) {
      return cities.map((city, index) => {
        const { city_id, name } = city

        return (
          <option key={index} value={city_id}>{name}</option>
        )
      })
    }
  }

  _renderNeighborhoods = () => {
    const { cities, inputs } = this.state
    const { addressCity } = inputs

    const city = cities.find(p => p.city_id === Number(addressCity))

    if (city) {
      const { neighborhoods } = city

      const sorted = neighborhoods.sort((a, b) => a.name.localeCompare(b.name))
      this.setState({
        ...this.state,
        neighborhoods: sorted
      })
    }
  }

  render() {
    const { goToCheckout } = this.props
    const { inputs, canSave, saveLoading, loading, selectNeighborhood, success, cities, neighborhoods } = this.state
    const { addressCity, addressNeighborhood, addressStreet, /*addressNumber,*/ addressComplement } = inputs

    return (
      <RegisterAddressContainer>
        <Title>Adicionar Endereço</Title>

        <RegisterAddressContent>
          <Row>
            <Col xs={12} md={6} style={{ marginBottom: 15 }}>
              <Autocomplete
                suggestions={cities}
                onChange={this.onChangeField}
                disabled={loading}
                identifier="city_id"
                id="city"
                selected={inputs.addressCity}
                name="addressCity"
                placeholder="SELECIONE UMA CIDADE"
              />
            </Col>
            <Col xs={12} md={6}>
              {
                selectNeighborhood ? (
                  <>
                    <Autocomplete
                      suggestions={neighborhoods}
                      onChange={this.onChangeField}
                      disabled={addressCity.length === 0}
                      id="neighborhood"
                      identifier="neighborhood_id"
                      selected={inputs.addressNeighborhood}
                      name="addressNeighborhood"
                      placeholder="SELECIONE UM BAIRRO"
                    />
                  </>
                ) : (
                    <Input name="addressNeighborhood" value={addressNeighborhood} placeholder="DIGITE SEU BAIRRO" fontSize={16} height={10} textAlign="center" onChange={this.onChangeField} />
                  )
              }

            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col xs={12}>
              <Input name="addressStreet" value={addressStreet} placeholder="DIGITE SUA RUA E NÚMERO" fontSize={16} height={10} maxLength={127} textAlign="center" onChange={this.onChangeField} />
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col xs={12}>
              <Input name="addressComplement" value={addressComplement} placeholder="COMPLEMENTO. EX: APTO 110" fontSize={16} height={10} maxLength={39} textAlign="center" onChange={this.onChangeField} />
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <LaddaButton
                className="btn-ladda-primary btn-small"
                loading={saveLoading}
                size={XL}
                spinnerSize={30}
                spinnerColor="#ddd"
                spinnerLines={12}
                onClick={this._handleSave}
                disabled={!canSave}
              >
                Adicionar
              </LaddaButton>
            </Col>
          </Row>
        </RegisterAddressContent>

        {
          success ? <Redirect to={goToCheckout ? '/checkout' : '/endereco'} /> : null
        }
      </RegisterAddressContainer>
    )
  }
}

const mapStateToProps = ({ delivery, address, login, cart }) => ({
  _delivery: delivery,
  _address: address,
  _user: login.user,

  cartProducts: cart.products,
})

const mapDispatchToProps = dispatch => ({
  attemptGetCities: () => dispatch(Creators.cityListRequest()),
  attemptGetNeighborhoods: () => dispatch(Creators.neighborhoodListRequest()),
  attemptRegisterAddress: ({ firstname, lastname, street, neighborhood_id, complement }) => dispatch(Creators.addressRegisterRequest(firstname, lastname, street, neighborhood_id, complement)),
  attemptGetDeliveries: (neighborhood_id) => dispatch(Creators.deliveryListRequest(neighborhood_id)),
  setCurrentAddress: (address) => dispatch(Creators.setCurrentAddressRequest(address))
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAddress)
