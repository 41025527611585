import styled from 'styled-components'
import InputMasked from 'react-input-mask'

export const CheckoutContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #FFFFFF;
`
export const BRow = styled.div`
  display: flex;
  width: 100%;
  margin: ${({ marged }) => marged ? '16px 0' : '0'};
`
export const BCol = styled.div`
  width: ${({ width }) => width};
  margin-right: ${({ margedRight }) => margedRight ? '16px' : '0'};
`
export const Input = styled.input`
  height: ${({ height }) => height ? `${height}px` : '26px'};
  width: ${({ width, textAlign }) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid #707070; 
  color: #707070;
  font-family: Gilmer;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  padding: ${({ textAlign }) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};
  margin-bottom: 10px;

  &:focus {
    border: 1px solid ${({ cpfvalid }) => cpfvalid ? '#00BF91' : '#c0392b'};
  }

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const Button = styled.button`
  border-radius: 5px;
  border: 0;
  background-color: #efefef;
  color: #5e5e5e;
  cursor: pointer;
  font-size: 13px;
  padding: 13px;
  min-width: 50px;
`
export const InputMask = styled(InputMasked)`
  height: ${({ height }) => height ? `${height}px` : '26px'};
  width: ${({ width, textAlign }) => width ? `${width}px` : textAlign === 'center' ? '100%' : 'calc(100% - 10px)'};
  border-radius: 10px;
  border: 1px solid ${({ cpfvalid }) => cpfvalid ? '#00BF91' : '#c0392b'};
  color: #707070;
  font-family: Gilmer;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '22px'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  outline: none;
  background-color: #FFFFFF;
  background: ${({ image }) => image ? `url(${image}) no-repeat scroll 95% 50%` : 'unset'};
  padding: ${({ textAlign }) => textAlign === 'center' ? '15px 0 15px 0' : '15px 0 15px 10px'};

  &::placeholder {
    font-family: Gilmer-Light;
  }
`
export const Title = styled.h2`
  font-weight: 900;
  font-family: Gilmer-Bold;
  font-size: 28px;
`
export const SubTitle = styled.h2`
  font-weight: 900;
  font-family: Gilmer;
  font-size: 18px;
`
export const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e74c3c;
  color: #c0392b;
  width: calc(100% - 92px);
  padding: 20px;
  margin-bottom: 10px;

  h3 {
    font-family: Gilmer-Bold;
    font-weight: 900;
    font-size: 16px;
    margin-top: -10px;
  }

  p {
    font-size: 14px;
    margin: 0px;
  }
`
export const Error = styled.p`
  color: #b73535;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 5px;
`

export const PicPayContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PicPayText = styled.p`
  font-family: Gilmer;
  font-size: 18px;
  line-height: 30px;
  color: #A8A8A8;
`
export const PicPayInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 468px;

  img {
    align-self: center;
    width: 100%;
    height: 60px;
  }
`
export const PicPayContainerQRcode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

   img {
    width: 200px;
    border: solid 1px #DDD;
    border-radius: 4px;
  }
  a { 
    margin-top: 15px;
    color: #21c25e;
  }

`

export const HealthInsuranceContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin-top: 26px;
    display : flex;
    flex-direction : column;

    p {
      font-size: 12px;
      color: #999;
    }

    label,span{
      flex : 1;
      margin-bottom:5px;
      color : #757575;
      font-size:13px;
    }

    input{
      height: 5px;
      width: calc(100% - 10px);
      border-radius: 10px;
      border: 1px solid #707070;
      color: #707070;
      font-family: Gilmer;
      font-size: 14px;
      text-align: left;
      outline: none;
      background-color: #FFFFFF;
      background: unset;
      padding: 15px 0 15px 10px;
      width: 100%;
      max-width: 200px;
      margin-bottom : 8px;
    }

    @media(max-width: 800px) {
      input {
        max-width:100%;
      }
  }
`
