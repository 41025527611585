import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingCircle from '@components/Icons/LoadingCircle'
import PrettySelect from '@components/PrettySelect'
import Body from '@components/Body'
import { SelectableDropdown, SelectableDropdownItem } from '@components/SelectableDropdown'
import Select from '@components/Select'
import CardIcon from '@components/Icons/Card'
import MoneyIcon from '@components/Icons/DolarSign'
import WalletIcon from '@components/Icons/Wallet'
import LoadingMoney from '@components/Icons/LoadingMoney'
import Cart from '@components/Cart'
import CompleteCheckout from '@components/CompleteCheckout'
import CurrencyInput from 'react-currency-input'
import { Formik, Form } from 'formik'
import { Creators } from '../../actions'
import ErrorCodes from '../../helpers/ErrorCodes'
import CpfValidator from '../../helpers/CpfValidator'
import { CreateCardToken, GetCardBrand, GetInstallments, CreateCardTokenException } from '../../helpers/PagSeguroWrapper'
import { floatToBRL } from '../../helpers/MoneyFormat'
import PagSeguroBanner from '../../assets/images/pagseguro_banner.gif'
import PicPayBanner from '../../assets/images/Banner-PicPay.png'
import { BRow, BCol, Input, InputMask, Title, SubTitle, ErrorsContainer, Error, PicPayContainer, PicPayInfo, PicPayText, PicPayContainerQRcode, HealthInsuranceContainer } from './styles'
import './index.css'
import GAEvents from '../../helpers/GAEvents'
import { purchase, setStep } from '../../helpers/ECEvents'
import productsApi from '../../services/ProductsAPI'

class Checkout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      onlinePaymentSelected: false,
      deliveryPaymentSelected: false,
      cardMethodSelected: false,
      healthInsuranceSelected: false,
      moneyMethodSelected: false,
      convMethodSelected: false,
      moneyChange: false,
      moneyChangeValue: 0,
      moneyChangeValueMasked: '',
      cpfCheckout: false,
      cpfCheckoutValid: false,
      cpfCheckoutValue: '',
      selectedConv: 0,
      hasCartMethod: false,
      hasMoneyMethod: false,
      hasCovenantMethod: false,
      hasGatewayMethod: false,
      processingOrder: false,
      paymentMethods: {
        credit: ['MasterCard', 'Visa', 'American', 'HiperCard'],
        debit: ['Elo', 'MasterCard'],
        gateway: []
      },
      paymentMethodOption: '',
      pagseguroHash: null,
      gatewaySession: null,
      onlinePaymentInputs: {
        cardNumber: '',
        cardBrand: '',
        cardExpire: '',
        cardCVC: '',
        cardName: '',
        cardCPF: ''
      },
      errors: [],
      hasActiveAddress: false,
      shouldCompleteCheckout: false,
      showCompleteAddress: false,
      installmentOnCreditCard: [],
      selectAmount: '',
      installmentOnCreditCardSelect: {
        quantity: '',
        installmentAmount: '',
        totalAmount: '',
        interestFree: null
      },
      pagSeguroPaymentMethod: false,
      picPayPaymentMethod: false,
      onlinePaymentMethods: false,
      picPayPaymentSelected: false,
      processingPicPay: false,
      picpayData: null,
      inputsOnline: {
        cardCPFValidate: null
      },
      healthInsurance: '',
    }
  }

  componentWillMount() {
    const { user, currentAddress } = this.props

    // There's something missing on user info?
    let completeCheckout = false
    if (user && currentAddress) {
      completeCheckout = (!user.birthdate || user.birthdate.length === 0 || user.telephone.length === 0) || (!currentAddress.postcode || currentAddress.postcode.length === 0)
    }

    // Load pagseguro script
    if (!window.pagseguroLoaded) {
      window.loadPagSeguro()
    }

    this.setState({
      hasActiveAddress: currentAddress ? true : false,
      shouldCompleteCheckout: completeCheckout
    })
  }

  componentWillReceiveProps(newProps) {
    const { clearCartProducts, attemptGetPaymentGatewaySession, history, attemptOrderInstallment, attempGetOrderPicpay } = this.props
    const { fetching, error, paymentMethods, orderFetching, orderError, order, gatewaySession, currentAddress, orderPipcayDetail } = newProps
    const { processingPicPay } = this.state;

    let newState = Object.assign({}, this.state)
    // Does we have active address?
    newState.hasActiveAddress = currentAddress ? true : false

    newState.onlinePaymentMethods = (currentAddress.not_deliverable && newState.picPayPaymentMethod) ? true : false

    newState.onlinePaymentMethods = (currentAddress.not_deliverable && newState.picPayPaymentMethod) ? true : false

    if (!fetching && paymentMethods !== null) {
      newState.paymentMethods.credit = paymentMethods.filter(p => p.type === 'credit')
      newState.paymentMethods.debit = paymentMethods.filter(p => p.type === 'debit')
      newState.paymentMethods.gateway = paymentMethods.filter(p => p.type === 'gateway')

      newState.hasCartMethod = paymentMethods.filter(p => p.type === 'credit' || p.type === 'debit').length > 0
      newState.hasMoneyMethod = paymentMethods.filter(p => p.type === 'money').length > 0
      newState.hasCovenantMethod = paymentMethods.filter(p => p.type === 'conven').length > 0
      newState.hasGatewayMethod = paymentMethods.filter(p => p.type === 'gateway' && p.name.toLowerCase() === 'pagseguro').length > 0
      newState.onlinePaymentMethods = paymentMethods.filter(p => p.type === 'gateway').length > 0

      newState.pagSeguroPaymentMethod = paymentMethods.filter(p => p.type === 'gateway' && p.name.toLowerCase() === 'pagseguro')
      newState.picPayPaymentMethod = paymentMethods.filter(p => p.type === 'gateway' && p.name.toLowerCase() === 'picpay').length > 0

      // If we do have gateway method available, let's request session for it
      if (!fetching && !error && newState.hasGatewayMethod && newState.gatewaySession === null && gatewaySession === null) {
        const [{ option_id }] = newState.pagSeguroPaymentMethod

        attemptGetPaymentGatewaySession({ payment_option_id: option_id })
      }

      // Gateway session received
      if (!fetching && !error && newState.gatewaySession === null && gatewaySession !== null) {
        newState.gatewaySession = gatewaySession

        // Set PagSeguro session
        window._PagSeguroDirectPayment.setSessionId(gatewaySession)

        // Get PagSeguro hash
        window._PagSeguroDirectPayment.onSenderHashReady((response) => {
          if (response && response.status !== 'error') {
            this.setState({
              pagseguroHash: response.senderHash
            })
          }
        })
      }
    }

    // We got an error :/
    if (!orderFetching && orderError) {
      newState.processingOrder = false
      newState.processingPicPay = false

      if (newState.errors.indexOf(orderError) === -1) {
        newState.errors.push(orderError)
      }
    }

    if (newState.processingOrder && !orderFetching && order !== null) {
      if (processingPicPay) {
        const { picpayReturn } = order;

        newState.picpayData = picpayReturn;
        if (!orderPipcayDetail) {
          attempGetOrderPicpay({
            orderId: order.order.order_id
          })
        }
      } else {
        newState.processingOrder = false
        clearCartProducts()
      }
    }

    if (newState.processingOrder && !orderFetching && orderPipcayDetail !== null) {

      if (orderPipcayDetail.status === 3) {
        newState.processingOrder = false
        newState.processingPicPay = false

      } else {
        setTimeout(() => {
          attempGetOrderPicpay({
            orderId: orderPipcayDetail.order_id
          })
        }, 3000);
      }
    }

    if (newState !== this.state) {
      this.setState(newState, () => {
        if (!newState.processingOrder && order !== null) {
          history.replace(`/pedido/${order.order.order_id}`)
        }
      })
    }

    if (newProps.cart !== this.props.cart) {

      if (this.state.onlinePaymentSelected) {
        setTimeout(() => {
          this.getInstallmentsOnCreditCard();
        }, 500);

        setTimeout(() => {
          const { installmentOnCreditCard, installmentOnCreditCardSelect } = this.state
          const installment = installmentOnCreditCard.find(i => i.quantity === installmentOnCreditCardSelect.quantity);

          if (installment) {
            const { attemptOrderInstallment } = this.props;
            attemptOrderInstallment(installment.totalAmount)
            this.setState(state => ({
              ...state,
              selectAmount: (state.selectAmount !== installment.totalAmount ? '' : state.selectAmount),
              installmentOnCreditCardSelect: {
                quantity: installmentOnCreditCardSelect.quantity,
                installmentAmount: installment.installmentAmount,
                totalAmount: installment.totalAmount
              }
            }))

          } else {
            attemptOrderInstallment(null)
            this.setState(state => ({
              ...state,
              selectAmount: '',
              installmentOnCreditCardSelect: {
                quantity: '',
                installmentAmount: '',
                totalAmount: ''
              }
            }))
          }
        }, 1500)
      }
    }
    if (order) {
      if (!orderPipcayDetail || orderPipcayDetail.status === 3) {
        const { products } = this.props.cart
        const { store } = this.props
        const shipping = order.total.find((value) => value.code === 'shipping')
        Promise.all(products.map(async product => {
          const detail = await productsApi.getProduct({ store_id: store.store_id, slug: product.slug })
          return detail
        })).then(completed => {
          const mapped = completed.map(({ data }, index) => {
            const found = products.find(original => original.slug === data.product.url_slug)
            return {
              ean: data.product.ean,
              model: found.model,
              price: found.price,
              quantity: found.quantity,
              categories: data.product.categories,
              manufacturer: data.product.manufacturer
            }
          })
          purchase({
            id: order.order.order_id,
            affiliation: order.order.store_name,
            revenue: Number(order.order.total).toFixed(2),
            tax: 0,
            shipping: Number(shipping.value).toFixed(2)
          }, mapped)
        })
      }
    }
  }



  onChangeField = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      [name]: value
    }), () => {
      // Validate fields
      this.validateCpf()
    })
  }

  onChangeOnlinePaymentField = ({ target: { name, value } }) => {

    if (name === 'cardNumber') {
      const cardNumber = value.replace(/\D/g, '')
      this.getInstallmentsOnCreditCard()

      GetCardBrand(cardNumber).then(brand => {
        const { onlinePaymentInputs } = this.state
        const { cardBrand } = onlinePaymentInputs

        if (cardBrand !== brand) {
          this.setState(state => ({
            ...state,
            onlinePaymentInputs: {
              ...state.onlinePaymentInputs,
              cardBrand: brand
            }
          }))
          this.getInstallmentsOnCreditCard()
        }
      })
    }

    this.setState(state => ({
      ...state,
      onlinePaymentInputs: {
        ...state.onlinePaymentInputs,
        [name]: value
      }
    }))
  }

  onChangeCurrencyField = (ev, maskedValue, floatValue) => {
    this.setState(state => ({
      ...state,
      moneyChangeValue: floatValue,
      moneyChangeValueMasked: maskedValue
    }))
  }

  onChangePaymentMethod = (current) => {
    const { paymentMethods } = this.props

    let paymentMethodOption = ''
    if (current === 'moneyMethodSelected' && !this.state[current]) {
      const moneyMethod = paymentMethods.find(p => p.type === 'money')
      paymentMethodOption = moneyMethod ? moneyMethod.option_id : ''
    }
    if (current === 'onlinePaymentSelected' && !this.state[current]) {
      const gatewayMethod = paymentMethods.find(p => p.type === 'gateway' && p.name.toLowerCase() === 'pagseguro')
      paymentMethodOption = gatewayMethod ? gatewayMethod.option_id : ''
    }
    if (current === 'picPayPaymentSelected' && !this.state[current]) {
      const gatewayMethod = paymentMethods.find(p => p.type === 'gateway' && p.name.toLowerCase() === 'picpay')
      paymentMethodOption = gatewayMethod ? gatewayMethod.option_id : ''
    }
    if (current === 'healthInsuranceSelected' && !this.state[current]) {
      paymentMethodOption = 19
    }

    this.setState(state => ({
      ...state,
      deliveryPaymentSelected: false,
      onlinePaymentSelected: false,
      cardMethodSelected: false,
      moneyMethodSelected: false,
      convMethodSelected: false,
      moneyChange: false,
      picPayPaymentSelected: false,
      selectedConv: 0,
      [current]: !state[current],
      paymentMethodOption,
      installmentOnCreditCard: [],
    }), () => {
      // Did we select any of delivery payment methods?
      this.setState(state => ({
        deliveryPaymentSelected: state.cardMethodSelected || state.moneyMethodSelected
      }))
    })
  }

  validateFields = () => {
    const { store } = this.props
    const { config } = store
    const { config_cpf_checkout } = config
    const { cpfCheckout, cpfCheckoutValid, paymentMethodOption, onlinePaymentSelected, onlinePaymentInputs, installmentOnCreditCardSelect, picPayPaymentSelected, healthInsuranceSelected, healthInsurance } = this.state
    const { cardNumber, cardBrand, cardExpire, cardCVC, cardName, cardCPF } = onlinePaymentInputs

    if (onlinePaymentSelected) {
      const validateCardCPF = CpfValidator(cardCPF)
      const cardNumberOnlyDigits = /^\d+$/.test(cardNumber.replace(/\s/g, ''))
      const cardExpireOnlyDigits = /^\d+$/.test(cardExpire.replace(/\s/g, '').replace('/', ''))
      const cardNameValid = /^[a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*( [a-záàâãéèêíïóôõöúçñ]([-']?[a-záàâãéèêíïóôõöúçñ]+)*)+\s?$/i.test(cardName)

      if (cardNumberOnlyDigits && cardBrand.length > 0 && cardExpireOnlyDigits && cardCVC.length > 0 && cardNameValid && validateCardCPF && installmentOnCreditCardSelect.quantity > 0) {
        return true
      }

      return false
    } else {
      if (picPayPaymentSelected) return true
      if (paymentMethodOption === '') return false

      if (healthInsuranceSelected) {
        if (!healthInsurance.length > 0 || !cpfCheckoutValid) {
          return false
        } else {
          return true
        }
      }

      // Do we need CPF?
      if (cpfCheckout || (config_cpf_checkout && config_cpf_checkout === true)) {
        // Is it CPF valid?
        return cpfCheckoutValid
      }

      return true
    }
  }

  validateCpf = () => {
    const { cpfCheckoutValue, cpfCheckoutValid } = this.state
    const bValid = CpfValidator(cpfCheckoutValue)

    if (bValid !== cpfCheckoutValid) {
      this.setState({
        cpfCheckoutValid: bValid
      })
    }
  }

  checkout = () => {
    const { currentAddress, products } = this.props
    const { onlinePaymentSelected, shouldCompleteCheckout } = this.state

    if (currentAddress && products) {
      // Should we confirm some data before process the checkout?
      if (onlinePaymentSelected && shouldCompleteCheckout) {
        // Dounce state

        setTimeout(() => {
          this.setState({
            showCompleteAddress: true
          })
        }, 500)
      } else {
        setTimeout(() => {
          this.setState({
            processingOrder: true,
            errors: []
          })
        }, 500)

        // Kinda of debounce
        setTimeout(() => {
          this.processCheckout()
        }, 2000)
      }
    }
  }

  finishCheckoutCompletion = ({ address, customer }) => {
    setTimeout(() => {
      this.setState({
        processingOrder: true,
        errors: [],
        showCompleteAddress: false,
        shouldCompleteCheckout: false
      }, () => {
        // Kinda of debounce
        setTimeout(() => {
          this.processCheckout()
        }, 2000)
      })
    }, 500)
  }

  processCheckout = async () => {
    const { currentAddress, products, deliveries, attemptRegisterOrder, attemptRegisterWithdrawlOrder } = this.props
    const { moneyChange, moneyChangeValue, paymentMethodOption, cpfCheckoutValue, pagseguroHash, onlinePaymentSelected, onlinePaymentInputs, installmentOnCreditCardSelect, picPayPaymentSelected, healthInsuranceSelected } = this.state
    // Deliveries
    const { delivery_fee, free_from } = deliveries[0]
    const deliveryFee = Number(delivery_fee)
    const deliveryFreeFrom = Number(free_from)

    const _moneyChange = moneyChange ? moneyChangeValue : 0

    try {
      const { neighborhood, not_deliverable } = currentAddress
      const { city } = neighborhood

      // Order total amount
      let orderTotal = 0

      // Initial checkout info
      let obj = {
        address_id: currentAddress.id,
        city_id: city.city_id,
        payment_code: 'mypharma',
        payment_option_id: paymentMethodOption,
        payment_money_change: _moneyChange,
        sub_method: '',
        card_token: '',
        sender_hash: '',
        card_holder: '',
        cpf: cpfCheckoutValue,
        products: products.map(p => {
          // Increment order total
          orderTotal += (Number(p.price.toFixed(2)) * p.quantity)
          console.log(p)
          return {
            product_id: p.id,
            quantity: p.quantity
          }
        })
      }

      // Not free delivery?
      if (orderTotal < deliveryFreeFrom || deliveryFreeFrom === 0) {
        if (!not_deliverable) orderTotal += deliveryFee
      }

      // Are we processing on online payment checkout?
      if (onlinePaymentSelected) {
        const { cardName, cardNumber, cardBrand, cardCVC, cardExpire, cardCPF } = onlinePaymentInputs
        const cardToken = await CreateCardToken({
          cardNumber,
          cardBrand,
          cardCVC,
          cardExpire
        })

        // Parse order total
        orderTotal = Number(orderTotal).toFixed(2)

        const { quantity, installmentAmount } = installmentOnCreditCardSelect;
        let paymentCustomField = [];
        paymentCustomField.push(installmentOnCreditCardSelect);

        // Setup pagseguro info
        obj.payment_code = 'pagseguro'
        obj.sub_method = 'creditCard'
        obj.card_token = cardToken
        obj.sender_hash = pagseguroHash
        obj.payment_custom_field = JSON.stringify(paymentCustomField)
        obj.cpf = cardCPF
        obj.card_holder = cardName
        obj.installments = {
          quantity: quantity,
          amount: installmentAmount,
          noInterestInstallmentQuantity: 2
        }
      }

      if (healthInsuranceSelected) {
        obj.payment_custom_field = this.state.healthInsurance
        obj.payment_code = 'convenio'
      }

      // Are we processing on picpay payment checkout?
      if (picPayPaymentSelected) {
        obj.payment_code = 'picpay'
        obj.sub_method = 'Picpay'
        orderTotal = Number(orderTotal).toFixed(2)

        this.setState({
          processingPicPay: true,
        })
      }

      not_deliverable ? attemptRegisterWithdrawlOrder(obj) : attemptRegisterOrder(obj)

    } catch (ex) {
      const { errors } = this.state
      console.log(ex)
      if (ex instanceof CreateCardTokenException) {

        ex.errors.forEach(err => {
          let error_name = err.error.replace(/ /g, '_')
          if (err.code === 30400) error_name = 'invalid_creditcard'

          if (errors.indexOf(error_name) === -1) {
            errors.push(error_name)
          }
        })
      } else {
        alert(ex)
      }

      this.setState({
        processingOrder: false,
        processingPicPay: false,
        errors
      })
    }
  }
  getInstallmentsOnCreditCard = async () => {

    const { products, deliveries, currentAddress } = this.props
    const { onlinePaymentSelected, onlinePaymentInputs } = this.state
    const { not_deliverable } = currentAddress ? currentAddress : false


    const { delivery_fee, free_from } = deliveries[0]
    const deliveryFee = Number(delivery_fee)
    const deliveryFreeFrom = Number(free_from)


    try {
      let orderTotal = 0
      products.map(p => orderTotal += (Number(p.price.toFixed(2)) * p.quantity))

      if (orderTotal < deliveryFreeFrom || deliveryFreeFrom === 0) {
        if (!not_deliverable) orderTotal += deliveryFee
      }

      if (onlinePaymentSelected) {
        const { cardNumber, cardBrand } = onlinePaymentInputs;

        if (cardNumber && cardBrand) {
          orderTotal = Number(orderTotal).toFixed(2)
          const installments = await GetInstallments({
            amount: orderTotal,
            brand: cardBrand,
            maxInstallmentNoInterest: 2
          })
          this.setState(state => ({
            ...state,
            installmentOnCreditCard: installments,
          }))
        }
      }
    } catch (ex) {
      const { errors } = this.state
      console.log(ex)
      if (ex instanceof CreateCardTokenException) {

        ex.errors.forEach(err => {
          let error_name = err.error.replace(/ /g, '_')
          if (err.code === 30400) error_name = 'invalid_creditcard'

          if (errors.indexOf(error_name) === -1) {
            errors.push(error_name)
          }
        })
      } else {
        alert(ex)
      }
    }

  }

  setInstallmentOnOrder = ({ target: { value } }) => {
    const { installmentOnCreditCard } = this.state
    const { attemptOrderInstallment } = this.props;

    if (!value) value = 1;
    const installments = installmentOnCreditCard
      .find(installment => installment.quantity === Number(value))

    attemptOrderInstallment(installments.totalAmount)

    this.setState(state => ({
      ...state,
      selectAmount: installments.totalAmount,
      installmentOnCreditCardSelect: {
        quantity: installments.quantity,
        installmentAmount: installments.installmentAmount,
        totalAmount: installments.totalAmount,
        interestFree: installments.interestFree
      }
    }))
  };


  _renderCpfForm = () => {
    const { store } = this.props
    const { config } = store
    const { config_cpf_checkout } = config
    const { cpfCheckout, cpfCheckoutValue, cpfCheckoutValid } = this.state

    const handleCpfCheckoutChangeOption = ({ target: { value } }) => {
      this.setState({
        cpfCheckout: value === 'yes' ? true : false
      })
    }

    return (
      <React.Fragment>
        {
          !config_cpf_checkout || config_cpf_checkout === false ? (
            <React.Fragment>
              <BRow style={{ marginTop: 15 }}>
                <span>CPF na nota?</span>
              </BRow>

              <BRow>
                <BCol margedRight>
                  <div className="radio-button">
                    <input type="radio" name="cpfCheckoutValue" value="no" id="cpf-no-to-change" onChange={handleCpfCheckoutChangeOption} defaultChecked />
                    <label htmlFor="cpf-no-to-change"><span></span>Não</label>
                  </div>
                </BCol>
                <BCol>
                  <div className="radio-button">
                    <input type="radio" name="cpfCheckoutValue" value="yes" id="cpf-yes-to-change" onChange={handleCpfCheckoutChangeOption} />
                    <label htmlFor="cpf-yes-to-change"><span></span>Sim</label>
                  </div>
                </BCol>
              </BRow>
            </React.Fragment>
          ) : (
              <BRow style={{ marginTop: 15 }}>
                <span>CPF na nota</span>
              </BRow>
            )
        }

        {
          cpfCheckout || (config_cpf_checkout && config_cpf_checkout === true) ? (
            <BRow className={cpfCheckout ? 'animated slideInDown' : ''} style={{ flexDirection: 'column' }}>
              <InputMask
                id="cpfCheckoutValue"
                name="cpfCheckoutValue"
                value={cpfCheckoutValue}
                placeholder="Insira o CPF"
                mask="999.999.999-99"
                height={5}
                fontSize={14}
                onChange={this.onChangeField}
                cpfvalid={cpfCheckoutValid ? 1 : 0}
              />
              {
                !cpfCheckoutValid ? <Error>Por favor informe um CPF válido.</Error> : null
              }
            </BRow>
          ) : null
        }
      </React.Fragment>
    )
  }

  _renderHealthInsuranceCpfForm = () => {
    const { cpfCheckout, cpfCheckoutValue, cpfCheckoutValid } = this.state

    return (
      <React.Fragment>
        <BRow style={{ marginTop: 15 }}>
          <span>CPF</span>
        </BRow>
        <BRow className={cpfCheckout ? 'animated slideInDown' : ''} style={{ flexDirection: 'column' }}>
          <InputMask
            id="cpfCheckoutValue"
            name="cpfCheckoutValue"
            value={cpfCheckoutValue}
            placeholder="Insira o CPF"
            mask="999.999.999-99"
            height={5}
            fontSize={14}
            onChange={this.onChangeField}
            style={{ marginBottom: 20 }}
            cpfvalid={cpfCheckoutValid ? 1 : 0}
          />
          {
            !cpfCheckoutValid && cpfCheckoutValue.length > 0 ? <Error>Por favor informe um CPF válido.</Error> : null
          }
        </BRow>
      </React.Fragment>
    )
  }

  _renderPaymentMethods = (type) => {
    const { paymentMethods } = this.state
    const method = paymentMethods[type]

    if (method !== undefined) {
      return (
        <ul>
          {
            method.map((val, index) => {
              const { option_id, name } = val

              return (
                <li key={`${type}-${index}`}>
                  <div className="radio-button">
                    <input type="radio" name="paymentMethodOption" value={option_id} id={`option_${option_id}`} onChange={this.onChangeField} />
                    <label htmlFor={`option_${option_id}`}><span></span>{name}</label>
                  </div>
                </li>
              )
            })
          }
        </ul>
      )
    }
  }

  _renderMoneyChange = () => {
    const { moneyChange, moneyChangeValueMasked } = this.state

    const handleMoneyChangeOption = ({ target: { value } }) => {
      this.setState({
        moneyChange: value === 'yes' ? true : false
      })
    }

    return (
      <React.Fragment>
        <BRow>
          <span>Precisa de troco?</span>
        </BRow>
        <BRow>
          <BCol margedRight>
            <div className="radio-button">
              <input type="radio" name="needChange" value="no" id="no-to-change" onChange={handleMoneyChangeOption} defaultChecked />
              <label htmlFor="no-to-change"><span></span>Não</label>
            </div>
          </BCol>
          <BCol>
            <div className="radio-button">
              <input type="radio" name="needChange" value="yes" id="yes-to-change" onChange={handleMoneyChangeOption} />
              <label htmlFor="yes-to-change"><span></span>Sim</label>
            </div>
          </BCol>
        </BRow>
        {
          moneyChange ? (
            <BRow className="animated slideInDown">
              <CurrencyInput className="default-input" name="moneyChangeValue" placeholder="Valor do troco" prefix="R$ " decimalSeparator="," thousandSeparator="." value={moneyChangeValueMasked} onChangeEvent={this.onChangeCurrencyField} />
            </BRow>
          ) : null
        }
      </React.Fragment>
    )
  }

  _renderConvForm = () => {
    const { selectedConv } = this.state

    const handleConvSelection = ({ target: { value } }) => {
      this.setState({
        selectedConv: value
      })
    }

    return (
      <React.Fragment>
        <BRow>
          <Formik>
            <Form style={{ width: 150 }}>
              <Select options={[{ value: 1, label: 'oi' }]} onChange={handleConvSelection} />
            </Form>
          </Formik>
        </BRow>
        {
          selectedConv > 0 ? (
            <div className="animated slideInDown" style={{ marginTop: 10, zIndex: -1 }}>
              <BRow>
                <Input type="text" name="convID" placeholder="Digite seu ID" />
              </BRow>
              <BRow>
                <Input type="text" name="convPassword" placeholder="Digite sua senha" />
              </BRow>
            </div>
          ) : null
        }
      </React.Fragment>
    )
  }
  _renderInstallmentOnCreditCard = () => {
    const { installmentOnCreditCard } = this.state

    if (installmentOnCreditCard) {
      return installmentOnCreditCard.map((installments, index) => {
        const { quantity, installmentAmount, totalAmount, interestFree } = installments

        return (
          <option key={index} value={quantity}>
            { interestFree && quantity === 1 ? 'Pagamento à vista - ' + floatToBRL(totalAmount) :
              interestFree ? quantity + 'X de ' + floatToBRL(installmentAmount) + ' sem juros' :
                quantity + 'X de ' + floatToBRL(installmentAmount) + ' com juros '
            }
          </option>
        )
      })
    }
  }

  _renderOnlinePaymentForm = () => {
    const { onlinePaymentInputs, processingOrder, pagseguroHash, gatewaySession, selectAmount, installmentOnCreditCardSelect } = this.state
    const { cardNumber, cardBrand, cardExpire, cardCVC, cardName, cardCPF } = onlinePaymentInputs
    const { quantity, installmentAmount, totalAmount, interestFree } = installmentOnCreditCardSelect;

    const brandImage = cardBrand.length > 0 ? `https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/${cardBrand}.png` : null

    return (
      <div className={processingOrder ? 'scale-down-ver-top' : 'scale-up-ver-top'} style={{ width: 468 }}>
        { !pagseguroHash || !gatewaySession ? (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <LoadingCircle width={100} height={100} />
          </div>
        ) : (
            <>
              <InputMask
                id="cardNumber"
                name="cardNumber"
                value={cardNumber}
                autoComplete="cc-number"
                placeholder="Número do Cartão"
                mask="9999 9999 9999 9999"
                height={10}
                fontSize={16}
                image={brandImage}
                onChange={this.onChangeOnlinePaymentField}
                style={{ marginBottom: 10 }}
              />

              <BRow>
                <BCol width="50%">
                  <InputMask
                    id="cardExpire"
                    name="cardExpire"
                    value={cardExpire}
                    autoComplete="cc-exp"
                    placeholder="Validade"
                    mask="99/9999"
                    height={10}
                    fontSize={16}
                    onChange={this.onChangeOnlinePaymentField}
                  />
                </BCol>
                <BCol width="48%">
                  <Input type="number" name="cardCVC" autoComplete="cc-csc" maxLength={3} fontSize={16} height={10} value={cardCVC} placeholder="CVV" onChange={this.onChangeOnlinePaymentField} style={{ marginLeft: 10 }} />
                </BCol>
              </BRow>

              <Input type="text" name="cardName" autoComplete="cc-name" fontSize={16} height={10} value={cardName} placeholder="Nome do Titular" onChange={this.onChangeOnlinePaymentField} />
              <InputMask
                id="cardCPF"
                name="cardCPF"
                value={cardCPF}
                placeholder="CPF do Titular"
                mask="999.999.999-99"
                height={10}
                fontSize={16}
                onChange={this.onChangeOnlinePaymentField}
                style={{ marginBottom: 20 }}
              />
              <PrettySelect
                name="InstallmentOnCreditCard"
                value={selectAmount}
                placeholder={quantity ?
                  interestFree && quantity === 1 ? 'Pagamento a vista - ' + floatToBRL(totalAmount) :
                    interestFree ? quantity + 'X de ' + floatToBRL(installmentAmount) + ' sem juros' :
                      quantity + 'X de ' + floatToBRL(installmentAmount) + ' com juros'
                  : 'Em quantas parcelas deseja pagar?'}
                fontSize={16}
                textAlign="center"
                disabled={!CpfValidator(cardCPF)}
                onChange={this.setInstallmentOnOrder}
              >
                {this._renderInstallmentOnCreditCard()}
              </PrettySelect>
            </>
          )}
        {
          !CpfValidator(cardCPF) ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Error>Por favor informe um CPF válido.</Error>
            </div>
          ) : null
        }
      </div>
    )
  }

  _renderOnlineFormPicpay = () => {
    const { processingOrder } = this.state;

    return (
      <PicPayContainer>
        <PicPayInfo className={processingOrder ? 'scale-down-ver-top' : 'scale-up-ver-top'}>
          <img src={PicPayBanner} alt="banner picpay" title="Pague com picpay é rápido fácil e seguro" />
          <PicPayText>Pague com o PicPay, direto do seu celular. <br /> Ao finalizar a compra, um código será exibido. Para pagar basta escanear o código
          com seu PicPay.<br /> Ainda não tem conta ? <br /> Baixe o aplicativo gratuitamente na Play Store (Android) ou na Apple Store (Iphone).</PicPayText>
        </PicPayInfo>

      </PicPayContainer>
    );
  }


  _renderDeliveryPaymentMethdos = () => {
    const { cardMethodSelected, moneyMethodSelected, onlinePaymentSelected, errors, picPayPaymentSelected, healthInsuranceSelected, healthInsurance } = this.state
    const { currentAddress } = this.props;
    const { not_deliverable } = currentAddress ? currentAddress : false;

    // Render card payment method
    if (cardMethodSelected) {
      return (
        <div style={{ marginTop: 15 }} className="animated slideInLeft">
          <SubTitle>{!not_deliverable ? 'Pagamento na entrega' : 'Pagamento na retirada'}: Máquina</SubTitle>

          <BRow>
            <BCol style={{ marginRight: 60 }}>
              <strong>Crédito</strong>
              {this._renderPaymentMethods('credit')}
              <br />
              <strong>Débito</strong>
              {this._renderPaymentMethods('debit')}
            </BCol>
            <BCol>
              {this._renderCpfForm()}
            </BCol>
          </BRow>
        </div>
      )
    }

    // Render money payment method
    if (moneyMethodSelected) {
      return (
        <div style={{ marginTop: 15 }} className="animated slideInLeft">
          <SubTitle>{!not_deliverable ? 'Pagamento na entrega' : 'Pagamento na retirada'}: Dinheiro</SubTitle>

          <BRow>
            <BCol>
              {this._renderMoneyChange()}
              {this._renderCpfForm()}
            </BCol>
          </BRow>
        </div>
      )

    }

    // Render online payment method
    if (onlinePaymentSelected) {
      return (
        <div style={{ marginTop: 15 }} className="animated slideInLeft">
          {
            errors.length > 0 ? (
              <ErrorsContainer className="animated slideInLeft">
                <h3>Ooops. Tivemos alguns problemas ao processar seu pedido.</h3>

                {
                  errors.map((error, index) => {
                    return <p key={`error_${index}`}>- {ErrorCodes(error)}</p>
                  })
                }
              </ErrorsContainer>
            ) : null
          }

          <img src={PagSeguroBanner} alt="Banner PagSeguro" title="Compre com PagSeguro e fique sossegado" />
          {this._renderOnlinePaymentForm()}
        </div>
      )
    }

    if (picPayPaymentSelected) {
      return (
        <div style={{ marginTop: 15 }} className="animated slideInLeft">
          {
            errors.length > 0 ? (
              <ErrorsContainer className="animated slideInLeft">
                <h3>Ooops. Tivemos alguns problemas ao processar seu pedido.</h3>

                {
                  errors.map((error, index) => {
                    return <p key={`error_${index}`}>- {ErrorCodes(error)}</p>
                  })
                }
              </ErrorsContainer>
            ) : null
          }
          {this._renderOnlineFormPicpay()}
        </div>
      );
    }

    if (healthInsuranceSelected) {
      return (
        <HealthInsuranceContainer>
          <SubTitle>Forma de pagamento selecionada : Convênio</SubTitle>
          <p>insira o nome da empresa conveniada e finalize o pedido</p>
          <label htmlFor="healthInsurance">Empresa</label>
          <input
            type="text"
            className="default-input"
            placeholder="Nome da empresa"
            value={healthInsurance}
            name="healthInsurance"
            onChange={this.onChangeField}
          />
          {
            this._renderHealthInsuranceCpfForm()
          }
        </HealthInsuranceContainer>
      )
    }
  }

  render() {
    const { deliveries, products, user, currentAddress, store } = this.props
    const { config } = store
    const { config_navbar_color, config_conven_input_enabled } = config
    const { not_deliverable } = currentAddress ? currentAddress : false;
    const {
      deliveryPaymentSelected,
      onlinePaymentSelected,
      hasCartMethod,
      hasMoneyMethod,
      processingOrder,
      hasActiveAddress,
      shouldCompleteCheckout,
      showCompleteAddress,
      pagSeguroPaymentMethod,
      picPayPaymentMethod,
      processingPicPay,
      picpayData,
    } = this.state

    return (
      <Body>
        <BRow className="content">
          {
            !processingOrder ? (
              <BCol width="66%">
                <Title>Escolher forma de pagamento</Title>

                <BRow>
                  <BCol margedRight>
                    <SelectableDropdown
                      color="#707070"
                      selected={deliveryPaymentSelected}
                      dropdown={true}
                      placeholder={not_deliverable ? 'Pagar na Retirada' : 'Pagar na Entrega'}
                      onClick={() => {
                        GAEvents({
                          category: 'Checkout',
                          action: 'Click no botão Pagar na Entrega'
                        })
                        setStep(2, 'Pagar na entrega')
                      }}
                      icon={
                        <WalletIcon
                          width={20}
                          height={20}
                          color={deliveryPaymentSelected ? '#eee' : '#707070'}
                        />
                      }
                    >
                      {hasCartMethod ?
                        <SelectableDropdownItem
                          text='Cartão máquina'
                          icon={
                            <CardIcon
                              width={20}
                              height={20}
                              color="#707070" />
                          }
                          onSelect={() => {
                            this.onChangePaymentMethod('cardMethodSelected')

                          }}
                        />
                        : null}
                      {hasMoneyMethod ?
                        <SelectableDropdownItem
                          text='Dinheiro'
                          icon={<MoneyIcon width={20} height={20} color="#707070" />}
                          onSelect={() => this.onChangePaymentMethod('moneyMethodSelected')}
                        />
                        :
                        null}
                      {
                        config_conven_input_enabled === '1' &&
                        <SelectableDropdownItem
                          text='Convênio'
                          icon={
                            <FontAwesomeIcon color="#707070" icon={Icons.faHandshake} style={{ height: 20, width: 20 }} />
                          }
                          onSelect={() => this.onChangePaymentMethod('healthInsuranceSelected')} />
                      }

                    </SelectableDropdown>
                  </BCol>
                  {
                    (picPayPaymentMethod) || (pagSeguroPaymentMethod.length > 0 && !not_deliverable) ? (
                      <BCol margedRight>
                        <SelectableDropdown
                          color="#707070"
                          placeholder='Pagar Online'
                          selected={onlinePaymentSelected}
                          dropdown={true}
                          onChange={() => this.onChangePaymentMethod('onlinePaymentSelected')}
                          onClick={() => {
                            GAEvents({
                              category: 'Checkout',
                              action: 'Click no botão Pagar Online'
                            })
                            setStep(2, 'Pagar online')
                          }}
                          icon={<CardIcon width={20} height={20} color={onlinePaymentSelected ? '#eee' : '#707070'} />}
                        >
                          {
                            pagSeguroPaymentMethod.length > 0 && !not_deliverable ? (
                              <SelectableDropdownItem
                                text='Crédito'
                                icon={<FontAwesomeIcon color="#707070" icon={Icons.faCreditCard} style={{ marginRight: 10 }} />}
                                onSelect={() => this.onChangePaymentMethod('onlinePaymentSelected')}
                              />)
                              : null}
                          {
                            picPayPaymentMethod ? (
                              <SelectableDropdownItem
                                text='Picpay'
                                icon={<FontAwesomeIcon color="#707070" icon={Icons.faQrcode} style={{ marginRight: 10 }} />}
                                onSelect={() => this.onChangePaymentMethod('picPayPaymentSelected')}
                              />)
                              : null}
                        </SelectableDropdown>

                      </BCol>
                    ) : null
                  }
                </BRow>

                {this._renderDeliveryPaymentMethdos()}
              </BCol>
            ) : processingPicPay && picpayData ? (
              <BCol width="100%">
                <BRow>
                  <BCol width="100%">
                    <PicPayContainerQRcode>
                      <SubTitle>Abra o PicPay em seu telefone e escaneie o código abaixo :</SubTitle>
                      <img src={picpayData.qrcode.base64} alt={picpayData.referenceId} />
                      <a href={picpayData.qrcode.content} target="_blank" rel="noopener noreferrer">Site do Picpay</a>
                    </PicPayContainerQRcode>
                  </BCol>
                </BRow>
              </BCol>
            ) : (
                  <BCol width={processingPicPay ? "100%" : "66%"} style={{ textAlign: 'center' }}>
                    <SubTitle>Processando pedido...</SubTitle>
                    <LoadingMoney width={250} height={250} color={config_navbar_color} />
                  </BCol>
                )
          }
          {!processingPicPay ? (
            <BCol width="33%">
              {
                deliveries ? (
                  <Cart buttonText="Finalizar pedido" disabled={processingOrder || !this.validateFields()} onClick={this.checkout} />
                ) : null
              }
            </BCol>
          ) : null}
        </BRow>
        {
          shouldCompleteCheckout ? <CompleteCheckout show={showCompleteAddress} customer={user} address={currentAddress} onFinish={this.finishCheckoutCompletion} /> : null
        }
        {
          // We do not have a valid checkout. let's go back to home
          !processingOrder && products.length === 0 ? <Redirect to="/" /> : null
        }

        {
          // We do not have a valid address, let's go to address view
          !hasActiveAddress ? <Redirect to={{ pathname: '/endereco', state: { selectAddress: true } }} /> : null
        }
      </Body>
    )
  }
}

const mapStateToProps = ({ payment, address, cart, order, delivery, startup, login }) => ({
  fetching: payment.fetching,
  error: payment.error,
  paymentMethods: payment.paymentMethods,
  gatewaySession: payment.gatewaySession,
  currentAddress: address.currentAddress,
  products: cart.products,
  orderFetching: order.fetching,
  orderError: order.error,
  order: order.order,
  deliveries: delivery.deliveries,
  store: startup.store,
  user: login.user,
  cart: cart,
  orderPipcayDetail: order.orderPipcayDetail
})

const mapDispatchToProps = dispatch => ({
  attemptGetPaymentMethods: () => dispatch(Creators.paymentMethodListRequest()),
  attemptRegisterOrder: ({ address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field }) => dispatch(Creators.orderRegisterRequest(address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field)),
  attemptGetPaymentGatewaySession: ({ payment_option_id }) => dispatch(Creators.paymentGatewaySessionRequest(payment_option_id)),
  clearCartProducts: () => dispatch(Creators.cartSetProductRequest([])),
  attemptOrderInstallment: (orderInstallment) => dispatch(Creators.orderInstallmentRequest(orderInstallment)),
  getProductDetail: (slug) => dispatch(Creators.productDetailRequest(slug)),
  attemptRegisterWithdrawlOrder: ({ address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields }) => dispatch(Creators.orderWithdrawlRegisterRequest(address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields)),
  attempGetOrderPicpay: ({ orderId }) => dispatch(Creators.orderPicpayDetailRequest(orderId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
