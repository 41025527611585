import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import DeliveryBoy from '@components/Icons/DeliveryBoy'
import Button from '@components/Button'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddressCardContainer, InfoContainer, ActionContainer, AddressPrimary } from './styles'
import './index.css'

export default class AddressOption extends Component {
  static propTypes = {
    goToCheckout: PropTypes.bool,
  }

  static defaultProps = {
    goToCheckout: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      selected: false

    }
  }
  handleSelect = () => {
    const { selected } = this.state;
    this.setState({ selected: !selected })

  }

  render() {
    const { state, mobile, hasAddress } = this.props;
    const { selected } = this.state;
    
    return (
        <AddressCardContainer mobile={mobile}>
           {!hasAddress ? <DeliveryBoy width="35" height="35"/> : null}
          <InfoContainer>
            <AddressPrimary>
           {!hasAddress ? 'ENTREGA' : null}
            </AddressPrimary>
          </InfoContainer>
         { hasAddress ?
          <ActionContainer>
            <Link to={{pathname: '/endereco/add', state: { selectAddress: (state && state.selectAddress)}}}>
            <Button style={{width: 280}} color={selected ? 'secondary' : 'primary'} >
              <FontAwesomeIcon icon={Icons.faPlusCircle} style={{marginRight: 10 }}/>
              {'Adicionar um novo endereço'}
              </Button>
            </Link>
          </ActionContainer> : null}
          <ActionContainer>
           {!hasAddress  ? <Link to={{pathname: '/endereco/add', state: { selectAddress: (state && state.selectAddress)}}}>
            <Button style={{width: 200}} onClick={this.handleSelect} color={selected ? 'secondary' : 'primary'} >{'Adicionar endereco'}</Button>
            </Link> : null}
          </ActionContainer>
        </AddressCardContainer>
    )
  }
}
