const {
  REACT_APP_MASTER_API,
  REACT_APP_PRODUCTS_API,
  REACT_APP_S3_BUCKET,
  REACT_APP_LOCAL_ORIGIN,

  REACT_APP_SEARCH_ENGINE_API,

  FACEBOOK_APP_ID,
} = process.env

export default {
  productsApi: REACT_APP_PRODUCTS_API,
  masterApi: REACT_APP_MASTER_API,
  s3Bucket: REACT_APP_S3_BUCKET,
  localOrigin: REACT_APP_LOCAL_ORIGIN,
  searchEngineApi: REACT_APP_SEARCH_ENGINE_API,
  facebookId: FACEBOOK_APP_ID
}
