import { create } from 'apisauce'
import Config from '../config'

const api = create({
  baseURL: Config.masterApi
})

// Configuration
const setHeaders = (opts) => api.setHeaders(opts)
const setURL = (url) => api.setBaseURL(url)

// Methods
const startup = () => api.get('v1/startup')
const login = ({ email, password }) => api.post('/v1/customer/login', { email, password })
const renewToken = () => api.get('/v1/customer/renew')
const register = ({ store_id, firstname, lastname, telephone, email, password }) => api.post('/v1/customer/register', { store_id, firstname, lastname, telephone, email, password })
const accountUpdate = ({ firstname, lastname, telephone, email, birthdate }) => api.post('/v1/customer/update', { firstname, lastname, telephone, email, birthdate })
const findAccount = ({ email }) => api.post('/v1/customer/login/email', { email })
const recoverPassword = ({ email }) => api.post('/v1/customer/login/recover', { email })
const validateResetPasswordToken = ({ token }) => api.get(`/v1/customer/login/validate-reset-password/${token}`)
const resetPassword = ({ token, password }) => api.post(`/v1/customer/login/reset-password/${token}`, { password })
const getCategories = () => api.get('/v1/category')
const getDeliveries = ({ neighborhood_id }) => api.get(`v2/delivery/area/${neighborhood_id || ''}`)
const getDeliverySchedule = () => api.get('/v1/delivery/schedule')
const getPaymentMethods = () => api.get('v1/payment/methods')
const getAddresses = () => api.get('v2/address')
const getCities = () => api.get('v2/delivery/cities')
const getNeighborhoods = () => api.get('v1/delivery/neighborhoods') // DEPRECATED
const registerAddress = ({ firstname, lastname, street, neighborhood_id, complement, postcode = '', address_id = 0, not_deliverable }) => api.put('v2/address', { firstname, lastname, street, neighborhood_id, complement, postcode, address_id, not_deliverable })
const deleteAddress = (address_id) => api.delete(`v1/address/${address_id}`)
const registerOrder = ({ address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field }) => api.put('/v1/order', { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field })
const getOrderDetail = ({ order_id }) => api.get(`/v1/order/detail/${order_id}`)
const getOrders = () => api.get('v1/order')
const getBanners = () => api.get('v1/banner')
const getFeaturedProducts = () => api.get('v2/product/featured')
const getAboutUs = () => api.get('v1/about-us')
const startSession = ({ payment_option_id }) => api.post('/v1/gateway/session', { payment_option_id })
const getCart = () => api.get('v1/cart')
const updateCart = ({ products }) => api.put('v1/cart', { products })
const addSingleToCart = ({ product_ean, fingerprint, origin, customer_id }) => api.post('v1/cart/add-single', { product_ean, fingerprint, origin, customer_id })
const registerWithdrawlOrder = ({ address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields }) => api.put('/v1/order-withdrawl', { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields })
const getOrderPicpay = ({ orderId }) => api.get(`/v1/order-picpay/detail/${orderId}`)
const googleLogin = ({ code }) => api.get(`v1/auth/google/callback/${code}`)
const facebookLogin = ({ code }) => api.get(`v1/auth/facebook/callback/${code}`)
const getProductBySlug = ({ slug, fingerprint, customer_id = null }) => api.post(`v1/product/${slug}`, { fingerprint, customer_id })
const getRegionsDelivery = ({ neighborhood_id }) => api.get(`v2/delivery/regions/${neighborhood_id || ''}`)
const getAddressByCep = ({ postcode }) => api.get(`v2/location/${postcode}`)

export default {
  setHeaders,
  setURL,

  startup,
  login,
  renewToken,
  register,
  accountUpdate,
  findAccount,
  recoverPassword,
  validateResetPasswordToken,
  resetPassword,
  getCategories,
  getDeliveries,
  getDeliverySchedule,
  getPaymentMethods,
  getAddresses,
  getCities,
  getNeighborhoods,
  registerAddress,
  deleteAddress,
  registerOrder,
  getOrderDetail,
  getOrders,
  getBanners,
  getFeaturedProducts,
  getAboutUs,
  startSession,
  getCart,
  updateCart,
  addSingleToCart,
  registerWithdrawlOrder,
  getOrderPicpay,
  googleLogin,
  facebookLogin,
  getProductBySlug,
  getRegionsDelivery,
  getAddressByCep,
}
