import { call, put } from 'redux-saga/effects'
import Config from '../config'
import { Creators } from '../actions'

export function * startup(api) {
  if (process.env.NODE_ENV === 'development' && Config.localOrigin) {
    api.setHeaders({
      'X-Origin': Config.localOrigin
    })
  }

  const response = yield call(api.startup)
  const { ok, data } = response

  if (ok) {
    const { store } = data

    yield put(Creators.startupSuccess(store))
  } else {
    const { error } = data

    yield put(Creators.startupFailure(error))
  }
}
