import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons'
import DeliveryBoy from '@components/Icons/DeliveryBoy'
import { AddressCardContainer, InfoContainer, ActionContainer, AddressPrimary, Text } from './styles'
import './index.css'

export default class MobileAddressAdd extends Component {
  static propTypes = {
    hasAddress: PropTypes.bool
  }

  static defaultProps = {
    hasAddress: false
  }

  constructor(props) {
    super(props)

    this.state = {
      selected: false,
      className: 'animated slideInLeft'
    }
  }

  handleSelect = () => {
    const { selected } = this.state;
    this.setState({ selected: !selected })

  }

  render() {
    const { state, hasAddress } = this.props;
    const { className } = this.state;

    return (
        <AddressCardContainer className={className} to={{pathname: '/endereco/add', state: { selectAddress: (state && state.selectAddress)}}}>
          <button style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "100%" ,background: "none", border: 0, outline: 0, padding: 0, margin: 0}}>
              {!hasAddress ? <DeliveryBoy width="35" height="35"/> : null}    
            <InfoContainer>
              <AddressPrimary>
              {!hasAddress ? 'ENTREGA' : null} <br/>
              </AddressPrimary>
            </InfoContainer>

            { hasAddress ? (
              <ActionContainer>
                <FontAwesomeIcon icon={Icons.faPlusCircle} size="2x" color="#00BF91" onClick={this.handleSelect} style={{marginRight: 10}} />
                <Text> {'ADICIONAR NOVO ENDEREÇO'}</Text>
              </ActionContainer>
            )
            : null}

            <ActionContainer>
            { !hasAddress ? (
              <FontAwesomeIcon icon={Icons.faPlusCircle} size="2x" color="#00BF91" onClick={this.handleSelect} style={{marginRight: 10}} />
            ) : null}
            </ActionContainer> 
          </button>
        </AddressCardContainer>
    )
  }
}
