import React from 'react'

// Pages
import Home from '@views/Home'
import Register from '@views/Register'
import Contact from '@views/Contact'
import Checkout from '@views/Checkout'
import Aboutus from '@views/Aboutus'
import Address from '@views/Address'
import Order from '@views/Order'
import OrderHistory from '@views/OrderHistory'
import Profile from '@views/Profile'
import Category from '@views/Category'
import ResetPassword from "@views/ResetPassword"
import GoogleCallback from '@views/GoogleCallback'
import FacebookCallback from '@views/FacebookCallback'

// Mobile pages
import MobileHome from '@views/MobileHome'
import MobileOrderHistory from '@views/MobileOrderHistory'
import MobileOrder from '@views/MobileOrder'
import MobileAddress from '@views/MobileAddress'
import MobileAboutUs from '@views/MobileAboutUs'
import MobileContact from '@views/MobileContact'
import MobileCheckout from '@views/MobileCheckout'
import MobileCategory from '@views/MobileCategory'

// Routes
const routes = [
  { path: '/produtos/:productUrl?', exact: true, mypharmaBase: false, name: 'MyPharma', component: Home, mobileComponent: MobileHome },
  
  { path: '/departamentos/:categorySlug?/:subcategorySlug?', exact: true, mypharmaBase: false, name: 'MyPharma', render: (props) => {
    const { params } = props.match
    const { categorySlug } = params
    const isMobile  = window.innerWidth < 768

    if (categorySlug) {
      return isMobile ? <MobileHome {...props} /> : <Home {...props} />
    }

    return isMobile ? <MobileCategory {...props} /> : <Category {...props} />
  } },

  { path: '/registro', exact: true, mypharmaBase: false, name: 'Registro', component: Register },
  { path: '/contato', exact: true, mypharmaBase: false, name: 'Contato', component: Contact, mobileComponent: MobileContact },
  { path: '/checkout', exact: true, mypharmaBase: false, name: 'Checkout', component: Checkout, mobileComponent: MobileCheckout },
  { path: '/sobre', exact: true, mypharmaBase: false, name: 'Sobre nós', component: Aboutus, mobileComponent: MobileAboutUs },
  { path: '/endereco', exact: true, mypharmaBase: false, name: 'Meus Endereços', component: Address, mobileComponent: MobileAddress },
  { path: '/endereco/add', exact: true, mypharmaBase: false, name: 'Adicionar Endereço', component: Address, mobileComponent: MobileAddress },
  { path: '/pedido/:orderId', exact: true, mypharmaBase: false, name: 'Acompanhamento de Pedidos', component: Order, mobileComponent: MobileOrder },
  { path: '/pedido', exact: true, mypharmaBase: false, name: 'Histórico de Pedidos', component: OrderHistory, mobileComponent: MobileOrderHistory },
  { path: '/perfil', exact: true, mypharmaBase: false, name: 'Perfil', component: Profile },
  { path: '/recuperar-senha/:token', exact: true, mypharmaBase: false, name: 'Recuperar Senha', component: ResetPassword },
  { path: '/google/callback/:code?', exact: true, mypharmaBase: false, name: 'Login Google', component: GoogleCallback },
  { path: '/facebook/callback/:code?', exact: true, mypharmaBase: false, name: 'Login Facebook', component: FacebookCallback },

  // Mobile native routes
  { path: '/carrinho', exact: true, mypharmaBase: true, name: 'Carrinho', render: (props) => {
    return <MobileHome {...props} isMypharmaBase={true} />
  } },
  { path: '/info-dropdown/:dropdownName', exact: true, mypharmaBase: true, name: 'MyPharma', render: (props) => {
    const { params } = props.match
    const { dropdownName } = params

    return <MobileHome {...props} isMypharmaBase={true} openDropDown={dropdownName} />
  } },
]

export default routes
