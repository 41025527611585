import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore } from '../redux/StartupRedux'
import { loggedToken } from '../redux/LoginRedux'

const storeId = ({ startup }) => loadedStore(startup)
const userToken = ({ login }) => loggedToken(login)

export function * getAddresses(api) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)

  api.setHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })
  
  const response = yield call(api.getAddresses)
  const { ok, data } = response

  if (ok) {
    const { addresses } = data

    yield put(Creators.addressListSuccess(addresses))
  } else {
    const { error } = data

    yield put(Creators.addressListFailure(error))
  }
}

export function * registerAddress(api, { firstname, lastname, street, neighborhood_id, complement, not_deliverable }) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)

  api.setHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })
  
  const response = yield call(api.registerAddress, { firstname, lastname, street, neighborhood_id, complement, not_deliverable })
  const { ok, data } = response

  if (ok) {
    const { address } = data

    yield put(Creators.addressRegisterSuccess(address))
  } else {
    const { error } = data

    yield put(Creators.addressRegisterFailure(error))
  }
}

export function * updateAddress(api, { address_id, firstname, lastname, street, neighborhood_id, complement, postcode }) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)

  api.setHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })
  
  const response = yield call(api.registerAddress, { address_id, firstname, lastname, street, neighborhood_id, complement, postcode })
  const { ok, data } = response

  if (ok) {
    const { address } = data

    yield put(Creators.addressUpdateSuccess(address))
  } else {
    const { error } = data

    yield put(Creators.addressUpdateFailure(error))
  }
}

export function * deleteAddress(api, { address_id }) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)

  api.setHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })
  
  const response = yield call(api.deleteAddress, address_id)
  const { ok, data } = response

  if (ok) {
    const { deleted_id } = data

    yield put(Creators.addressDeleteSuccess(deleted_id))
  } else {
    const { error } = data

    yield put(Creators.addressDeleteFailure(error))
  }
}
