import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  order: null,
  orders: null,
  orderDetail: null,
  orderInstallment: null,
  orderPipcayDetail: null
})

export const orderRegisterRequest = (state = INITIAL_STATE, { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, payment_custom_field }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    order: null
  })

export const orderRegisterSuccess = (state = INITIAL_STATE, { order }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    order
  })

export const orderRegisterFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    order: null,
    error
  })

export const orderDetailRequest = (state = INITIAL_STATE, { order_id }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    orderDetail: null,
    order: null
  })

export const orderDetailSuccess = (state = INITIAL_STATE, { order }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    orderDetail: order
  })

export const orderDetailFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    orderDetail: null,
    error
  })

export const orderListRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    orders: null
  })

export const orderListSuccess = (state = INITIAL_STATE, { orders }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    orders
  })

export const orderListFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    orders: null,
    error
  })

  export const orderInstallmentRequest = (state = INITIAL_STATE, { orderInstallment }) =>
  Object.assign({}, state, {
    orderInstallment: orderInstallment,
    fetching: true
  })
  
  export const orderInstallmentSuccess = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: false
  })

export const orderWithdrawlRegisterRequest = (state = INITIAL_STATE, { address_id, city_id, payment_code, payment_option_id, payment_money_change, products, cpf, sub_method, sender_hash, card_token, card_holder, installments, shipping_custom_fields }) =>
Object.assign({}, state, {
  fetching: true,
  error: false,
  order: null
})

export const orderWithdrawlRegisterSuccess = (state = INITIAL_STATE, { order }) =>
Object.assign({}, state, {
  fetching: false,
  error: false,
  order
})

export const orderPicpayDetailRequest = (state = INITIAL_STATE, { orderId }) => 
Object.assign({}, state , {
  fetching: true,
  error: false,
}) 


export const orderPicpayDetailSuccess = (state = INITIAL_STATE, { order }) => 
Object.assign({}, state , {
  fetching: false,
  error: false,
  orderPipcayDetail: order
}) 
/**
 * Handlers
 */

export const HANDLERS = {
  [Types.ORDER_REGISTER_REQUEST]: orderRegisterRequest,
  [Types.ORDER_REGISTER_SUCCESS]: orderRegisterSuccess,
  [Types.ORDER_REGISTER_FAILURE]: orderRegisterFailure,

  [Types.ORDER_DETAIL_REQUEST]: orderDetailRequest,
  [Types.ORDER_DETAIL_SUCCESS]: orderDetailSuccess,
  [Types.ORDER_DETAIL_FAILURE]: orderDetailFailure,

  [Types.ORDER_LIST_REQUEST]: orderListRequest,
  [Types.ORDER_LIST_SUCCESS]: orderListSuccess,
  [Types.ORDER_LIST_FAILURE]: orderListFailure, 

  [Types.ORDER_INSTALLMENT_REQUEST]: orderInstallmentRequest,
  [Types.ORDER_INSTALLMENT_SUCCESS]: orderInstallmentSuccess,

  [Types.ORDER_WITHDRAWL_REGISTER_REQUEST]:orderWithdrawlRegisterRequest,
  [Types.ORDER_WITHDRAWL_REGISTER_SUCCESS]:orderWithdrawlRegisterSuccess,

  [Types.ORDER_PICPAY_DETAIL_REQUEST]:orderPicpayDetailRequest,
  [Types.ORDER_PICPAY_DETAIL_SUCCESS]:orderPicpayDetailSuccess

}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
