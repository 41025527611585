import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore } from '../redux/StartupRedux'
import { loggedToken } from '../redux/LoginRedux'

const storeId = ({ startup }) => loadedStore(startup)
const userToken = ({ login }) => loggedToken(login)

export function * login(api, { email, password }) {
  const response = yield call(api.login, { email, password })
  const { ok, data, problem } = response

  if (ok) {
    let { user, access_token } = data

    user.accessToken = access_token

    yield put(Creators.loginSuccess(user))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.loginFailure(error))
  }
}

export function * recoverPassword(api, { email }) {
  const response = yield call(api.recoverPassword, { email })
  const { ok, data, problem } = response

  if (ok) {
    yield put(Creators.recoverPasswordSuccess())
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.recoverPasswordFailure(error))
  }
}

export function * validateResetPasswordToken(api, { token }) {
  const response = yield call(api.validateResetPasswordToken, { token })
  const { ok, data, problem } = response

  if (ok) {
    const { valid } = data

    yield put(Creators.validateResetPasswordTokenSuccess(valid))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.validateResetPasswordTokenFailure(error))
  }
}

export function * resetPassword(api, { token, password }) {
  const response = yield call(api.resetPassword, { token, password })
  const { ok, data, problem } = response

  if (ok) {
    yield put(Creators.resetPasswordSuccess())
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.resetPasswordFailure(error))
  }
}

export function * refreshToken(api) {
  const token = yield select(userToken)
  api.setHeaders({'Authorization': `Bearer ${token}`})
  const response = yield call(api.renewToken)
  const { ok, data, problem } = response

  if (ok) {
    let { user, access_token } = data

    user.accessToken = access_token

    yield put(Creators.refreshTokenSuccess(user))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.refreshTokenFailure(error))
  }
}

export function * register(api, { firstname, lastname, telephone, email, password }) {
  const store_id = yield select(storeId)

  const response = yield call(api.register, { store_id, firstname, lastname, telephone, email, password })
  const { ok, data, problem } = response

  if (ok) {
    let { user, access_token } = data

    user.accessToken = access_token

    yield put(Creators.registerSuccess(user))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.registerFailure(error))
  }
}

export function * loginGoogle(api, { code }) {
  const store_id = yield select(storeId)
  api.setHeaders({'store-id': `${store_id}`})

  const response = yield call(api.googleLogin, { code })
  const { ok, data, problem } = response  
 
  if (ok) {
    let { user, access_token } = data

    user.accessToken = access_token

    yield put(Creators.loginGoogleSuccess(user))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.loginFailure(error))
  }
}

export function * loginFacebook(api, { code }) {
  const store_id = yield select(storeId)
  api.setHeaders({'store-id': `${store_id}`})

  const response = yield call(api.facebookLogin, { code })
  const { ok, data, problem } = response  
 
  if (ok) {
    let { user, access_token } = data

    user.accessToken = access_token

    yield put(Creators.loginFacebookSuccess(user))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.loginFailure(error))
  }
  
}

export function * getAddressByCep(api, { postcode }) {
  const store_id = yield select(storeId)
  api.setHeaders({'store-id': `${store_id}`})

  const response = yield call(api.getAddressByCep, { postcode });
  const { data, ok , problem } = response;

  if (ok) {
    yield put(Creators.searchByCepSuccess(data));
    
  } else {
    let error = problem;
    
    if (data) {
      error = data.error;
    }
    yield put(Creators.searchByCepFailure(error));
  }
}