import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import Body from '@components/Body'
import RegisterAddress from '@components/RegisterAddress'
import { isLogged } from '../../redux/LoginRedux'
import AddressCard from '@components/AddressCard'
import AddressOption from '@components/AddressOption'
import AddressAddCard from '@components/AddressAddCard'
import LoadingCircle from '@components/Icons/LoadingCircle'
import InsufficientCartItems from '@components/InsufficientCartItems'
import MissingData from '@components/MissingData'
import { Col, Row } from 'react-flexbox-grid'
import { Creators } from '../../actions'
import { Title } from './styles'

class Address extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      selected: false,
      cartItemsModal: false,
      cartTotal: 0,
      deliveryMinimumValue: 0,
      goToCheckout: false,
      goToAddNew: false,
      goToHome: false,
      addresses: [],
      currentAddress: 0,
      saveLoading: false,
      loggedIn: null,
      customerModal: false,
      customer: null
    }
  }
  componentDidMount() {
    const { attemptGetAddresses, location, setCartProceed, loggedIn } = this.props

    // Does we have register failure state?

    if (location.state) {
      const { registerFailure } = location.state

      if (registerFailure) {
        setTimeout(() => {
          this.setState(registerFailure)
        }, 1500)
      }
    }
    if (loggedIn) {
      attemptGetAddresses();
    } else {
      setCartProceed(true);
    }

  }
  componentWillReceiveProps(newProps) {
    const { location, setCurrentAddress, attemptGetDeliveries } = this.props
    const { fetching, addresses, currentAddress, deliveryFetching, deliveries, cartProducts, _address, user } = newProps
    const { saveLoading } = this.state;
    const { state } = location;
    let newState = Object.assign({}, this.state)

    if (saveLoading && !_address.fetching && _address.address !== null) {
      const { address_id, street, complement, neighborhood, not_deliverable } = _address.address

      if (state && state.selectAddress) {
        setCurrentAddress({
          id: address_id,
          number: 'S/N',
          street,
          complement,
          neighborhood,
          not_deliverable
        })
        attemptGetDeliveries(neighborhood.neighborhood_id)

      } else {
        newState.success = true
      }

      newState.saveLoading = false
      newState.goToCheckout = true
    }

    if (!fetching && addresses !== null) {
      if (location.state) {
        const { selectAddress } = location.state

        if (selectAddress && addresses.length === 0) {
          newState.goToAddNew = true
        }
      }

      addresses.forEach(addr => {
        const { address_id, street, complement, neighborhood, postcode, not_deliverable } = addr

        const index = newState.addresses.findIndex(x => x.id === address_id)
        if (index === -1) {
          newState.addresses.push({
            id: address_id,
            number: 'S/N',
            street,
            complement,
            neighborhood,
            postcode,
            not_deliverable
          })
        } else {
          newState.addresses[index] = {
            id: address_id,
            number: 'S/N',
            street,
            complement,
            neighborhood,
            postcode,
            not_deliverable
          }
        }
      })
      newState.loading = false
    }

    if (currentAddress !== null) {
      newState.currentAddress = currentAddress.id
    }

    // Check if address delivery fee is valid
    if (!deliveryFetching && deliveries && cartProducts && newState.selected && location.state && location.state.selectAddress) {
      if (deliveries.length > 0) {
        const { minimum_value } = deliveries[0]
        let totalCartValue = 0

        cartProducts.forEach(product => {
          const { price, quantity } = product
          totalCartValue += (quantity * price)
        })

        // Check if cart total is below delivery minimum value
        if (Number(totalCartValue) < Number(minimum_value) && !currentAddress.not_deliverable) {
          newState.cartItemsModal = true
          newState.cartTotal = Number(totalCartValue)
          newState.deliveryMinimumValue = Number(minimum_value)
        } else {
          newState.goToCheckout = true
        }
      }
    }

    if (user && (!user.firstname || !user.lastname || !user.telephone.length > 0)) {
      newState.customerModal = true
      newState.customer = user
    }

    if (newState !== this.state) {
      this.setState(newState)
    }
  }

  _handleAddDefaulAddress = () => {
    const { attemptRegisterAddress, user, store, cities, addresses } = this.props;
    const { config } = store;
    const { neighborhoods } = cities[0];
    const { neighborhood_id } = neighborhoods[0];

    if (addresses === null) {
      this.setState({
        saveLoading: true
      }, () => {
        attemptRegisterAddress({
          firstname: user.firstname,
          lastname: user.lastname,
          street: config.config_address,
          neighborhood_id: neighborhood_id,
          complement: config.config_store_city,
          not_deliverable: true
        })
      })

    } else {
      const address = addresses.filter(a => a.not_deliverable === true).length > 0
      if (!address) {
        this.setState({
          saveLoading: true
        }, () => {
          attemptRegisterAddress({
            firstname: user.firstname,
            lastname: user.lastname,
            street: config.config_address,
            neighborhood_id: neighborhood_id,
            complement: config.config_store_city,
            not_deliverable: true
          })
        })
      }
    }
  }

  _handleAddressDelete = (id) => {
    const { currentAddress, attemptDeleteAddress, setCurrentAddress } = this.props
    if (currentAddress && id === currentAddress.id) {
      setCurrentAddress(null)
    }

    attemptDeleteAddress(id)
  }

  _handleAddressSelect = (id) => {
    const { setCurrentAddress, attemptGetDeliveries } = this.props
    const { addresses } = this.state

    const address = addresses.find(p => p.id === id)

    if (address) {
      this.setState({
        selected: true
      }, () => {
        setCurrentAddress(address)
        attemptGetDeliveries(address.neighborhood.neighborhood_id)
      })
    }
  }
  _handleWithdrawlSelect = () => {
    const { setCurrentAddress, attemptGetDeliveries, location } = this.props
    const { state } = location;
    const { addresses } = this.state
    const hasAddress = addresses.length > 0 ? addresses.filter(a => a.not_deliverable === true).length > 0 : false


    if (hasAddress) {
      const address = addresses.find(a => a.not_deliverable === true)

      if (address) {
        this.setState({
          selected: true
        }, () => {
          setCurrentAddress(address)
          attemptGetDeliveries(address.neighborhood.neighborhood_id)
        })
      }
    } else {
      if (state && state.selectAddress) {
        this._handleAddDefaulAddress()
      }
    }

  }

  _renderMissingCustomerData = () => {
    const { customerModal, customer } = this.state
    const { loggedIn } = this.props


    const _onFinish = () => {
      this.setState({
        customerModal: false
      })
    }

    const isMobile = window.innerWidth <= 426 ? true : false;

    const modalOptions = {
      open: true,
      center: true,
      closeOnEsc: false,
      closeOnOverlayClick: false,
      showCloseIcon: false,
      styles: {
        modal: {
          width: isMobile ? '100%' : '40%',
          borderRadius: '5px'
        }
      },
      onClose: () => { }
    }

    return (
      <Modal {...modalOptions} open={loggedIn && customerModal}>
        <MissingData customer={customer} onFinish={_onFinish} />
      </Modal>
    )
  }

  _handelRegisterAddressFailure = ({ totalCartValue, minimumValue }) => {
    const { location } = this.props
    let currentLocationState = location.state ? location.state : {}

    this.props.history.replace('/endereco', {
      ...currentLocationState,
      registerFailure: {
        cartItemsModal: true,
        cartTotal: totalCartValue,
        deliveryMinimumValue: minimumValue
      }
    })
  }

  _renderCartModal = () => {
    const { cartItemsModal, cartTotal, deliveryMinimumValue } = this.state
    const { currentAddress } = this.props;
    const { not_deliverable } = currentAddress ? currentAddress : false

    const handleModalClose = () => {
      this.setState({
        cartItemsModal: false
      })
    }

    const handleSelectProducts = () => {
      this.setState({
        goToHome: true
      })
    }

    return (
      <Modal open={cartItemsModal && !not_deliverable} onClose={handleModalClose} showCloseIcon={false} styles={{ modal: { borderRadius: 5, minWidth: '60%' } }} center>
        <InsufficientCartItems total={cartTotal} minimumValue={deliveryMinimumValue} onSelectProducts={handleSelectProducts} onSelectAddress={handleModalClose} />
      </Modal>
    )
  }

  _renderAddNew = () => {
    const { location } = this.props;
    const { state } = location;
    const { addresses } = this.state;
    const hasAddress = addresses.length > 0 ? addresses.filter(a => a.not_deliverable !== true).length > 0 : false


    return (
      <AddressAddCard
        state={state}
        hasAddress={hasAddress}
        goToCheckout={(state && state.selectAddress)}
      />
    );
  }

  _renderOptions = () => {
    const { addresses, currentAddress } = this.state
    const { store } = this.props;
    const { config } = store;

    const hasAddress = addresses.length > 0 ? addresses.filter(a => a.not_deliverable === true).length > 0 : false
    if (hasAddress) {
      return addresses.filter(a => a.not_deliverable === true).map((address, index) => {
        const { id, street, neighborhood } = address
        const { city } = neighborhood
        const { zone } = city
        const selected = currentAddress === id

        return (
          <AddressOption
            key={index}
            id={id}
            slideIn={true}
            slideInOffet={index + 1}
            selected={selected}
            street={street}
            number={config.config_store_number}
            neighborhood={neighborhood.name}
            city={city.name}
            uf={zone.code}
            onSelect={this._handleWithdrawlSelect}
          />
        )
      })
    } else {

      return (
        <AddressOption
          street={config.config_address}
          number={config.config_store_number}
          city={config.config_store_city}
          onSelect={this._handleWithdrawlSelect}
        />
      )
    }
  }

  _renderItems = () => {
    const { addresses, currentAddress } = this.state

    if (addresses.length > 0) {
      return addresses.filter(a => a.not_deliverable === false).map((address, index) => {
        const { id, street, number, neighborhood } = address
        const { city } = neighborhood
        const { zone } = city
        const selected = currentAddress === id

        return (
          <AddressCard
            key={index}
            id={id}
            slideIn={true}
            slideInOffet={index + 1}
            selected={selected}
            street={street}
            number={number}
            neighborhood={neighborhood.name}
            city={city.name}
            uf={zone.code}
            onDelete={this._handleAddressDelete}
            onSelect={this._handleAddressSelect}
          />
        )
      })
    }
  }

  render() {
    const { location, loggedIn } = this.props
    const { pathname, state } = location
    const { loading, goToCheckout, goToHome } = this.state
    if (!loggedIn) {
      return null
    }

    let title = 'Selecione uma das opções'

    if (state && state.selectAddress) {
      title = 'Selecione uma opção'
    }

    return (
      <Body>
        {
          pathname === '/endereco/add' ? (
            <RegisterAddress goToCheckout={(state && state.selectAddress)} onMinimumValueFail={this._handelRegisterAddressFailure} />

          ) : (
              <React.Fragment>
                {
                  loading ? (
                    <Row>
                      <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <LoadingCircle width={100} height={100} />
                      </Col>
                    </Row>
                  ) : (
                      <React.Fragment>
                        <Row >
                          <Col xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Title style={{ alignSelf: 'center' }}>{title}</Title>
                            <div style={{ marginTop: 20 }}>
                              {
                                this._renderOptions()
                              }
                              {this._renderItems()}

                              {
                                this._renderAddNew()
                              }
                            </div>
                          </Col>
                        </Row>
                        {
                          goToCheckout ? <Redirect to="/checkout" /> : null
                        }
                        {
                          goToHome ? <Redirect to="/produtos" /> : null
                        }
                      </React.Fragment>
                    )
                }
              </React.Fragment>
            )
        }
        {
          this._renderMissingCustomerData()
        }

        {
          // Cart check modal
          this._renderCartModal()
        }
      </Body>
    )
  }
}

const mapStateToProps = ({ address, delivery, cart, login, startup }) => ({
  fetching: address.fetching,
  error: address.error,
  addresses: address.addresses,
  currentAddress: address.currentAddress,

  deliveries: delivery.deliveries,
  deliveryFetching: delivery.fetching,

  cartProducts: cart.products,
  _address: address,
  cities: delivery.cities,
  loggedIn: isLogged(login),
  user: login.user,
  store: startup.store
})

const mapDispatchToProps = dispatch => ({
  attemptGetAddresses: () => dispatch(Creators.addressListRequest()),
  setCartProceed: (proceed) => dispatch(Creators.cartSetProceed(proceed)),
  attemptDeleteAddress: (address_id) => dispatch(Creators.addressDeleteRequest(address_id)),
  setCurrentAddress: (address) => dispatch(Creators.setCurrentAddressRequest(address)),
  attemptGetDeliveries: (neighborhood_id) => dispatch(Creators.deliveryListRequest(neighborhood_id)),
  attemptRegisterAddress: ({ firstname, lastname, street, neighborhood_id, complement, not_deliverable }) => dispatch(Creators.addressRegisterRequest(firstname, lastname, street, neighborhood_id, complement, not_deliverable)),

})

export default connect(mapStateToProps, mapDispatchToProps)(Address)
