import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Body from '@components/MobileBody'
import OrderProgress from '@components/MobileOrderProgress'
import Button from '@components/Button'
import PillsIcon from '@components/Icons/Pills'
import { Col, Row } from 'react-flexbox-grid'
import { Title, SubTitle, Divider, DividerContent, ProductImage, ProductText, InfoText, ResumeContainer, ResumeText } from './styles'
import { floatToBRL } from '../../helpers/MoneyFormat'
import { Creators } from '../../actions'
import Config from '../../config'
import NoImage from '../../assets/images/noimage.png'

const STATUS_LABEL = [
  {
    name: 'pendente',
    component: <React.Fragment key={1}>
      <Title>Aguardando</Title>
      <SubTitle>Aprovação</SubTitle>
    </React.Fragment>
  },
  {
    name: 'rejeitado',
    component: <React.Fragment key={2}>
      <Title>Pedido</Title>
      <SubTitle>Rejeitado</SubTitle>
    </React.Fragment>
  },
  {
    name: 'aceito',
    component: <React.Fragment key={3}>
      <Title>Pedido</Title>
      <SubTitle>Aceito</SubTitle>
    </React.Fragment>
  }
]

const PROGRESS_DATA = [
  {
    text: 'Pedido recebido',
    names: ['recebido'],
    active: true
  },
  {
    text: 'Aguardando aprovação',
    names: ['pendente'],
    active: false
  },
  {
    text: 'Pedido aceito/recusado',
    names: ['aceito', 'rejeitado'],
    active: false
  }
]

const STATUS_LABEL_ONLINE = [
  {
    name: 'pendente',
    component: <React.Fragment key={1}>
      <Title>Aguardando</Title>
      <SubTitle>Pagamento</SubTitle>
    </React.Fragment>
  },
  {
    name: 'rejeitado',
    component: <React.Fragment key={2}>
      <Title>Pedido</Title>
      <SubTitle>Rejeitado</SubTitle>
    </React.Fragment>
  },
  {
    name: 'aceito',
    component: <React.Fragment key={3}>
      <Title>Pedido</Title>
      <SubTitle>Aceito</SubTitle>
    </React.Fragment>
  },
  {
    name: 'pagamento realizado',
    component: <React.Fragment key={4}>
      <Title>Pagamento</Title>
      <SubTitle>Realizado</SubTitle>
    </React.Fragment>
  },
]

const PROGRESS_DATA_ONLINE = [
  {
    text: 'Pedido recebido',
    names: ['recebido'],
    active: true
  },
  {
    text: 'Aguardando pagamento',
    names: ['pendente'],
    active: false
  },
  {
    text: 'Pagamento realizado',
    names: ['pagamento realizado'],
    active: false
  },
  {
    text: 'Pedido aceito/recusado',
    names: ['aceito', 'rejeitado'],
    active: false
  }
]

class Order extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      order: {}
    }
  }

  componentDidMount() {
    const { match, attemptGetOrderDetail } = this.props
    const { orderId } = match.params

    if (orderId) {
      attemptGetOrderDetail(orderId)
    }
  }

  componentWillReceiveProps(newProps) {
    const { fetching, order } = newProps
    const { attemptGetOrderDetail } = this.props;

    if (!fetching && order !== null) {
      const { payment_address_1, payment_address_2, payment_city, payment_company, orderStatus, orderTotals, payment_method, payment_custom_field, order_status_id, payment_code } = order

      const products = order.orderProducts.map(p => {
        const { product_id, model, name, price, quantity, product } = p
        const { image, ean, ms } = product

        return {
          id: product_id,
          name: `${model} ${name}`,
          ean: ean,
          ms: ms,
          image: image,
          price: Number(price),
          quantity: Number(quantity)
        }
      })

      this.setState({
        loading: false,
        order: {
          PaymentMethod: payment_method,
          status: orderStatus,
          totals: orderTotals,
          payment_method: payment_method,
          address: {
            street: payment_address_1,
            neighborhood: payment_address_2,
            city: payment_city,
            complement: payment_company
          },
          products,
          payment_custom_field: payment_custom_field,
          payment_code
        }
      })

      if (order_status_id === 1 || order_status_id === 22) {
        setTimeout(() => {
          attemptGetOrderDetail(order.order_id)
        }, 3000)
      }
    }
  }

  _renderProducts = () => {
    const { order } = this.state

    if (order) {
      const { products } = order

      return products.map((product, index) => {
        const { name, ean, ms, image, quantity, price } = product

        const productImage = image && image.split('/')[0] !== 'catalog' ? `${Config.s3Bucket}${image}` : NoImage

        return (
          <Row key={index} style={{ marginTop: 30 }}>
            <Col xs={4}>
              <ProductImage src={productImage} alt={name} />
            </Col>
            <Col xs={8}>
              <ProductText><b>Produto:</b> {name}</ProductText>
              <ProductText><b>EAN:</b> {ean}</ProductText>
              {ms ? <ProductText><b>Código Ministério da Saúde:</b> {ms}</ProductText> : null}
              <ProductText><b>Quantidade:</b> {quantity}</ProductText>
              <ProductText><b>Valor:</b> {floatToBRL(price)}</ProductText>
            </Col>
          </Row>
        )
      })
    }
  }

  _renderDeliveryAddress = () => {
    const { order } = this.state

    if (order) {
      const { address } = order
      const { street, neighborhood, city, complement } = address

      return (
        <React.Fragment>
          <InfoText style={{ marginTop: 30 }}>Sua compra será enviada para o endereço abaixo: </InfoText>
          <InfoText>{street}, {neighborhood} {complement && complement.length > 0 ? `(${complement})` : ''} - {city}</InfoText>
          <InfoText bold style={{ marginTop: 35 }}>Alguma dúvida? Entre em contato com a gente. ;-)</InfoText>
        </React.Fragment>
      )
    }
  }

  _renderResume = () => {
    const { order } = this.state

    if (order) {
      const { totals, payment_custom_field, payment_code } = order

      let subTotal = 0
      let deliveryFee = 0
      let total = 0

      let TotalAmount = 0
      let Quantity = 0

      totals.forEach(p => {
        if (p.code === 'sub_total') {
          subTotal = Number(p.value)
        }
        if (p.code === 'shipping') {
          deliveryFee = Number(p.value)
        }
        if (p.code === 'total') {
          total = Number(p.value)
        }
      })

      if (payment_custom_field && payment_code !== 'convenio') {
        const paymentCustomField = JSON.parse(payment_custom_field);
        const { quantity, totalAmount } = paymentCustomField[0];

        TotalAmount = Number(totalAmount)
        Quantity = quantity
        total = TotalAmount - total
      }

      return (
        <div style={{ margin: 30 }}>
          <ResumeContainer>
            <ResumeText bold>Valor dos itens: </ResumeText>
            <ResumeText style={{ justifySelf: 'flex-end', marginLeft: 'auto' }}>{floatToBRL(subTotal)}</ResumeText>
          </ResumeContainer>
          <ResumeContainer>
            <ResumeText bold>Valor do frete: </ResumeText>
            <ResumeText style={{ justifySelf: 'flex-end', marginLeft: 'auto' }}>{floatToBRL(deliveryFee)}</ResumeText>
          </ResumeContainer>

          {payment_custom_field ?
            <React.Fragment>
              <ResumeContainer>
                <ResumeText bold>Parcela{Quantity > 1 ? 's' : ''}: </ResumeText>
                <ResumeText style={{ justifySelf: 'flex-end', marginLeft: 'auto' }}>{Quantity}</ResumeText>
              </ResumeContainer>
              {TotalAmount > (subTotal + deliveryFee) ?
                <ResumeContainer>
                  <ResumeText bold>Juros:</ResumeText>
                  <ResumeText style={{ justifySelf: 'flex-end', marginLeft: 'auto' }}>{(floatToBRL(total))}</ResumeText>
                </ResumeContainer> : null}
            </React.Fragment>
            : null}

          <Divider style={{ marginBottom: 0, marginTop: 15 }} />

          <ResumeContainer style={{ marginBottom: 15, marginTop: 15 }}>
            <ResumeText bold>Valor total: </ResumeText>
            <ResumeText style={{ justifySelf: 'flex-end', marginLeft: 'auto' }}>{floatToBRL(TotalAmount || total)}</ResumeText>
          </ResumeContainer>

          <Divider style={{ marginBottom: 15 }} />

          <ResumeContainer style={{ marginBottom: 15, marginTop: 15 }}>
            <ResumeText bold>Forma de pagamento: </ResumeText>
            <div style={{ justifySelf: 'flex-end', marginLeft: 'auto' }}>
              <ResumeText>
                {order.paymentMethod}
              </ResumeText>
              <br />
              {order.payment_code === 'convenio' && (
                <ResumeText>
                  {`Convenio (${order.payment_custom_field})`}
                </ResumeText>
              )}
            </div>
          </ResumeContainer>
        </div>
      )
    }
  }

  render() {
    const { loading, order } = this.state

    let progressData = PROGRESS_DATA
    if (!loading) {
      if (order.payment_code.toLowerCase() !== 'picpay') {
        const orderStatus = order.status.name.toLowerCase()
        const activeIndex = PROGRESS_DATA.findIndex(p => p.names.indexOf(orderStatus) !== -1)
        progressData = PROGRESS_DATA.map((p, index) => {
          if (index <= activeIndex) {
            p.active = true;
          }
          if (orderStatus === 'aceito') {
            p.text = 'Pedido aceito'
          }
          if (orderStatus === 'rejeitado') {
            p.text = 'Pedido recusado'
          }

          return p
        })

      } else {
        progressData = PROGRESS_DATA_ONLINE
        const activeIndex = PROGRESS_DATA_ONLINE.findIndex(p => p.names.indexOf(order.status.name.toLowerCase()) !== -1)

        const orderStatus = order.status.name.toLowerCase()
        progressData = PROGRESS_DATA_ONLINE.map((p, index) => {
          if (index <= activeIndex) {
            p.active = true;

            if (p.names.indexOf(order.status.name.toLowerCase()) !== -1) {
              if (orderStatus === 'pendente') {
                p.text = 'Aguardando pagamento'
              }

              if (orderStatus === 'pagamento realizado') {
                p.text = 'Pagamento realizado'
              }

              if (orderStatus === 'rejeitado') {
                p.text = 'Pedido rejeitado'

              }

              if (orderStatus === 'aceito') {
                p.text = 'Pedido aceito'
              }
            }
          }
          return p
        })

      }
    }

    return (
      <Body>
        {
          !loading ? (
            <React.Fragment>
              <Row style={{ borderBottom: '1px solid #A8A8A8' }}>
                <Col xs={12} style={{ paddingBottom: 20 }}>
                  {
                    order.payment_code.toLowerCase() !== 'picpay' ? (
                      STATUS_LABEL.map(p => {
                        const { name, component } = p

                        if (name === order.status.name.toLowerCase()) return component

                        return null
                      })

                    ) : (
                        STATUS_LABEL_ONLINE.map(p => {
                          const { name, component } = p

                          if (name === order.status.name.toLowerCase()) return component

                          return null
                        })
                      )
                  }
                </Col>
              </Row>

              <Row>
                <Col xs={12} style={{ paddingBottom: 50 }}>
                  <OrderProgress data={progressData} color={order.status.name.toLowerCase() === "rejeitado" ? "#FF4646" : "#00BF91"} />
                </Col>
              </Row>

              <Divider>
                <DividerContent>
                  <PillsIcon width={23} height={20} />
                </DividerContent>
              </Divider>

              <Row>
                <Col xs={12}>
                  <strong>Confira as informações</strong>

                  {this._renderProducts()}
                </Col>
              </Row>

              <Divider style={{ marginBottom: 0 }}>
                <DividerContent>
                  <PillsIcon width={23} height={20} />
                </DividerContent>
              </Divider>

              <Row>
                <Col xs={12}>
                  {this._renderResume()}
                </Col>
              </Row>

              <Divider style={{ marginBottom: 0 }}>
                <DividerContent>
                  <PillsIcon width={23} height={20} />
                </DividerContent>
              </Divider>

              <Row>
                <Col xs={12}>
                  {this._renderDeliveryAddress()}
                </Col>
              </Row>

              <Row style={{ marginTop: 25 }}>
                <Col xs={12} style={{ textAlign: 'center' }}>
                  <Link to="/">
                    <Button style={{ width: 230 }}>Ir à página inicial</Button>
                  </Link>
                </Col>
              </Row>
            </React.Fragment>
          ) : null
        }
      </Body>
    )
  }
}

const mapStateToProps = ({ order }) => ({
  fetching: order.fetching,
  order: order.orderDetail
})

const mapDispatchToProps = dispatch => ({
  attemptGetOrderDetail: (order_id) => dispatch(Creators.orderDetailRequest(order_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Order)
