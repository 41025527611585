import { createActions } from 'reduxsauce'

export const { Types, Creators } = createActions({
  cartSetProductRequest: ['products'],
  cartListProductRequest: null,
  cartSetProceed: ['proceed'],
  cartProceedBySocialLogin: ['proceedCheckout'],
  cartProceedHome: ['proceedHome'],

  cartGetRemoteRequest: null,
  cartGetRemoteSuccess: ['products'],
  cartGetRemoteFailure: ['error'],

  cartUpdateRemoteRequest: ['products'],
  cartUpdateRemoteSuccess: null,
  cartUpdateRemoteFailure: ['error'],

  cartAddSingleRequest: ['product_ean', 'origin'],
  cartAddSingleSuccess: null,
  cartAddSingleFailure: ['error'],

  startupRequest: null,
  startupSuccess: ['store'],
  startupFailure: ['error'],

  productListRequest: ['category_id', 'params'],
  productListSuccess: ['data'],
  productListFailure: ['error'],

  productSearchRequest: ['param'],
  productSearchSuccess: ['products', 'fingerprint'],
  productSearchFailure: ['error'],

  productDetailRequest: ['slug'],
  productDetailSuccess: ['product'],
  productDetailFailure: ['error'],

  featuredProductListRequest: null,
  featuredProductListSuccess: ['products'],
  featuredProductListFailure: ['error'],

  loginRequest: ['email', 'password'],
  loginSuccess: ['user'],
  loginFailure: ['error'],

  loginGoogleRequest:['code'],
  loginGoogleSuccess:['user'],

  loginFacebookRequest: ['code'],
  loginFacebookSuccess: ['user'],

  searchByCepRequest: ['postcode'],
  searchByCepSuccess: ['groups'],
  searchByCepFailure: ['error'],

  findAccountRequest: ['email'],
  findAccountSuccess: ['exist', 'name'],
  findAccountFailure: ['error'],

  recoverPasswordRequest: ['email'],
  recoverPasswordSuccess: null,
  recoverPasswordFailure: ['error'],

  validateResetPasswordTokenRequest: ['token'],
  validateResetPasswordTokenSuccess: ['valid'],
  validateResetPasswordTokenFailure: ['error'],

  resetPasswordRequest: ['token', 'password'],
  resetPasswordSuccess: null,
  resetPasswordFailure: ['error'],

  registerRequest: ['firstname', 'lastname', 'telephone', 'email', 'password'],
  registerSuccess: ['user'],
  registerFailure: ['error'],

  refreshTokenRequest: null,
  refreshTokenSuccess: ['user'],
  refreshTokenFailure: ['error'],

  accountUpdateRequest: ['firstname', 'lastname', 'telephone', 'email', 'birthdate'],
  accountUpdateSuccess: ['user'],
  accountUpdateFailure: ['error'],

  logoutRequest: null,
  setStoreRedirect: ['status', 'expireIn'],
  setUserRequest: ['user'],
  setLoginDialogRequest: ['show'],

  categoryListRequest: null,
  categoryListSuccess: ['categories'],
  categoryListFailure: ['error'],

  deliveryListRequest: ['neighborhood_id'],
  deliveryListSuccess: ['deliveries'],
  deliveryListFailure: ['error'],

  deliveryScheduleRequest: null,
  deliveryScheduleSuccess: ['schedule'],
  deliveryScheduleFailure: ['error'],

  regionsListRequest: ['neighborhood_id'],
  regionsListSuccess: ['regions'],

  paymentMethodListRequest: null,
  paymentMethodListSuccess: ['paymentMethods'],
  paymentMethodListFailure: ['error'],

  paymentGatewaySessionRequest: ['payment_option_id'],
  paymentGatewaySessionSuccess: ['gatewaySession'],
  paymentGatewaySessionFailure: ['error'],

  addressListRequest: null,
  addressListSuccess: ['addresses'],
  addressListFailure: ['error'],

  addressRegisterRequest: ['firstname', 'lastname', 'street', 'neighborhood_id', 'complement', 'not_deliverable'],
  addressRegisterSuccess: ['address'],
  addressRegisterFailure: ['error'],

  addressUpdateRequest: ['address_id', 'firstname', 'lastname', 'street', 'neighborhood_id', 'complement', 'postcode'],
  addressUpdateSuccess: ['address'],
  addressUpdateFailure: ['error'],

  addressDeleteRequest: ['address_id'],
  addressDeleteSuccess: ['deleted_id'],
  addressDeleteFailure: ['error'],

  setCurrentAddressRequest: ['address'],

  cityListRequest: null,
  cityListSuccess: ['cities'],
  cityListFailure: ['error'],

  neighborhoodListRequest: null,
  neighborhoodListSuccess: ['neighborhoods'],
  neighborhoodListFailure: ['error'],

  orderRegisterRequest: ['address_id', 'city_id', 'payment_code', 'payment_option_id', 'payment_money_change', 'products', 'cpf', 'sub_method', 'sender_hash', 'card_token', 'card_holder', 'installments', 'payment_custom_field'],
  orderRegisterSuccess: ['order'],
  orderRegisterFailure: ['error'],

  orderDetailRequest: ['order_id'],
  orderDetailSuccess: ['order'],
  orderDetailFailure: ['error'],

  orderListRequest: null,
  orderListSuccess: ['orders'],
  orderListFailure: ['error'],

  orderInstallmentRequest: ['orderInstallment'],
  orderInstallmentSuccess: null,

  orderWithdrawlRegisterRequest:['address_id', 'city_id', 'payment_code', 'payment_option_id', 'payment_money_change', 'products', 'cpf', 'sub_method', 'sender_hash', 'card_token', 'card_holder', 'installments', 'shipping_custom_fields'],
  orderWithdrawlRegisterSuccess:['order'],

  orderPicpayStatusRequest:['referenceId', 'payment_option_id'],
  orderPicpayStatusSuccess:['response'],
  
  orderPicpayDetailRequest: ['orderId'],
  orderPicpayDetailSuccess: ['order'],

  bannerListRequest: null,
  bannerListSuccess: ['banners'],
  bannerListFailure: ['error'],

  aboutUsRequest: null,
  aboutUsSuccess: ['content'],
  aboutUsFailure: ['error']
})
