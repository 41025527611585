import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore } from '../redux/StartupRedux'

const storeId = ({ startup }) => loadedStore(startup)

export function * getFeaturedProducts(api) {
  const store_id = yield select(storeId)
  api.setHeaders({
    'store-id': store_id
  })
  
  const response = yield call(api.getFeaturedProducts)
  const { ok, data } = response

  if (ok) {
    const { products } = data

    yield put(Creators.featuredProductListSuccess(products))
  } else {
    const { error } = data

    yield put(Creators.featuredProductListFailure(error))
  }
}
