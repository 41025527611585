import { create } from 'apisauce'
import Config from '../config'

const api = create({
  baseURL: Config.productsApi
})

const getProducts = ({ store_id, param }) => api.get(`/${store_id}/search/`, param)
const getProduct = ({ store_id, slug }) => api.get(`/${store_id}/slug/${slug}`)

export default {
  getProducts,
  getProduct
}
