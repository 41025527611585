import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: null,
  products: [],
  proceed: false,
  proceedCheckout: false,
  proceedHome: false
})

export const cartSetProductRequest = (state = INITIAL_STATE, { products }) =>
  Object.assign({}, state, {
    products
  })

export const cartListProductRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state)

export const cartSetProceed = (state = INITIAL_STATE, { proceed }) =>
  Object.assign({}, state, {
    proceed
  })

export const cartGetRemoteRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: null
  })

export const cartGetRemoteSuccess = (state = INITIAL_STATE, { products }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    products
  })

export const cartGetRemoteFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error
  })

export const cartUpdateRemoteRequest = (state = INITIAL_STATE, { products }) =>
  Object.assign({}, state, {
    fetching: true,
    error: null
  })

export const cartUpdateRemoteSuccess = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: false,
    error: null
  })

export const cartUpdateRemoteFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error
  })

export const cartAddSingleRequest = (state = INITIAL_STATE, { product_ean, origin }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null
  })

export const cartAddSingleSuccess = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: false
  })

export const cartAddSingleFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error
  })

export const cartProceedBySocialLogin = (state = INITIAL_STATE, { proceedCheckout }) =>
Object.assign({}, state, {
  proceedCheckout
})

export const cartProceedHome = (state = INITIAL_STATE, { proceedHome }) =>
Object.assign({}, state, {
  proceedHome
})

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.CART_SET_PRODUCT_REQUEST]: cartSetProductRequest,
  [Types.CART_LIST_PRODUCT_REQUEST]: cartListProductRequest,
  [Types.CART_SET_PROCEED]: cartSetProceed,

  [Types.CART_GET_REMOTE_REQUEST]: cartGetRemoteRequest,
  [Types.CART_GET_REMOTE_SUCCESS]: cartGetRemoteSuccess,
  [Types.CART_GET_REMOTE_FAILURE]: cartGetRemoteFailure,

  [Types.CART_UPDATE_REMOTE_REQUEST]: cartUpdateRemoteRequest,
  [Types.CART_UPDATE_REMOTE_SUCCESS]: cartUpdateRemoteSuccess,
  [Types.CART_UPDATE_REMOTE_FAILURE]: cartUpdateRemoteFailure,

  [Types.CART_ADD_SINGLE_REQUEST]: cartAddSingleRequest,
  [Types.CART_ADD_SINGLE_SUCCESS]: cartAddSingleSuccess,
  [Types.CART_ADD_SINGLE_FAILURE]: cartAddSingleFailure,

  [Types.CART_PROCEED_BY_SOCIAL_LOGIN]: cartProceedBySocialLogin,
  [Types.CART_PROCEED_HOME]: cartProceedHome,
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer
