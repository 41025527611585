import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FooterContainer, FooterRow, FooterCol, Img, FooterColEurofarma, ImgEurofarma } from './styles'
import AnvisaLogo from '../../assets/images/logo-anvisa.jpg'
import MypharmaLogo from '../../assets/images/logo-mypharma.jpg'
import SafeBrowsing from '../../assets/images/selo-google.jpg'
import Eurofarma from '../../assets/images/eurofarma.jpg'
import './index.css'

class Footer extends Component {
  render() {
    const { store } = this.props
    const { name: storeName, config } = store
    const { 
      config_name,
      config_cnpj,
      config_address,
      config_store_city,
      config_store_number,
      config_cep,
      config_phone,
      config_email,
      config_pharmacist_name,
      config_pharmacist_crf,
      config_url,
      config_eurofarma_partner
    } = config

    const storeUrl = config_url.replace('https://', '').replace('/', '')
    const zipcode = config_cep ? config_cep.replace('.', '') : '00000-000'
    const fullAddress = `${config_address}, ${config_store_number}, ${config_store_city}, ${zipcode}`
    const copyYear = new Date().getFullYear()

    return (
      <FooterContainer>
        <FooterRow>
          <FooterCol width="33%">
            <a href="http://portal.anvisa.gov.br/" target="_blank" rel="noopener noreferrer">
              <Img alt="agencia-nacional-de-vigilancia-sanitaria" src={AnvisaLogo} width="180" />
            </a>
          </FooterCol>

          {
            config_eurofarma_partner === "1" ? (
              <FooterColEurofarma width="33%">
                <p className="developed-by" style={{fontSize: 5}}>{copyYear} - Desenvolvido por MyPharma</p>
                <a href="https://www.mypharma.com.br/" target="_blank" rel="noopener noreferrer">
                  <ImgEurofarma alt="powered-by-mypharma" src={Eurofarma} />
                </a>
              </FooterColEurofarma>

            ) : (
              <FooterCol width="33%">
              <p className="developed-by" style={{fontSize: 8}}>{copyYear} - Desenvolvido por</p>
              <a href="https://www.mypharma.com.br/" target="_blank" rel="noopener noreferrer">
                <Img alt="powered-by-mypharma" src={MypharmaLogo} width="180" />
              </a>
            </FooterCol>

              )
          }



          <FooterCol width="33%">
            <a href={`https://transparencyreport.google.com/safe-browsing/search?url=${storeUrl}`} target="_blank" rel="noopener noreferrer">
              <Img alt="site-seguro" src={SafeBrowsing} width="180" />
            </a>
          </FooterCol>
        </FooterRow>
        <FooterRow borderBottom>
            <p style={{textAlign: 'center'}}>
              As informações contidas neste site não devem ser usadas para automedicação
              e não substituem, em hipótese alguma, as orientações dadas pelo profissional
              da área médica. Somente o médico está apto a diagnosticar qualquer problema
              de saúde e prescrever o tratamento adequado.
            </p>
        </FooterRow>

        <FooterRow>
          <p style={{textAlign: 'center'}}>
            {storeName} | {config_name} | CNPJ: {config_cnpj} | {fullAddress} | Telefone: {config_phone} | E-mail: {config_email} |
            Farmacêutico Responsável: {config_pharmacist_name} | CRF: {config_pharmacist_crf}
          </p>
        </FooterRow>
      </FooterContainer>
    )
  }
}

const mapStateToProps = ({ startup }) => ({
  store: startup.store
})

export default connect(mapStateToProps, null)(Footer)
