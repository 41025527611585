import { all, takeLatest } from 'redux-saga/effects'
import { Types } from '../actions'
import Api from '../services/API'
import SearchApi from '../services/SearchAPI'

// Sagas
import { startup } from './StartupSagas'
import { getProducts, getProduct } from './ProductSagas'
import { login, recoverPassword, validateResetPasswordToken, resetPassword, refreshToken, register, loginGoogle, loginFacebook, getAddressByCep } from './LoginSagas'
import { getCategories } from './CategorySagas'
import { getDeliveries, getDeliverySchedule, getCities, getNeighborhoods, getRegionsDelivery } from './DeliverySagas'
import { getPaymentMethods, getPaymentGatewaySession } from './PaymentSagas'
import { getAddresses, registerAddress, deleteAddress, updateAddress } from './AddressSagas'
import { registerOrder, getOrderDetail, getOrders, setInstallmentOrder, registerWithdrawlOrder, getOrderPicpayDetail } from './OrderSagas'
import { getBanners } from './BannerSagas'
import { getFeaturedProducts } from './FeaturedProductSagas'
import { getAboutUs } from './AboutUsSagas'
import { accountUpdate, findAccount } from './AccountSagas'
import { getCart, updateCart, addSingle } from './CartSagas'
import { searchProducts } from './SearchSagas'

export default function * rootSaga() {
  yield all([
    takeLatest(Types.STARTUP_REQUEST, startup, Api),
    takeLatest(Types.PRODUCT_LIST_REQUEST, getProducts, SearchApi),
    takeLatest(Types.PRODUCT_DETAIL_REQUEST, getProduct, Api),
    takeLatest(Types.LOGIN_REQUEST, login, Api),
    takeLatest(Types.RECOVER_PASSWORD_REQUEST, recoverPassword, Api),
    takeLatest(Types.VALIDATE_RESET_PASSWORD_TOKEN_REQUEST, validateResetPasswordToken, Api),
    takeLatest(Types.RESET_PASSWORD_REQUEST, resetPassword, Api),
    takeLatest(Types.REFRESH_TOKEN_REQUEST, refreshToken, Api),
    takeLatest(Types.REGISTER_REQUEST, register, Api),
    takeLatest(Types.CATEGORY_LIST_REQUEST, getCategories, Api),
    takeLatest(Types.DELIVERY_LIST_REQUEST, getDeliveries, Api),
    takeLatest(Types.DELIVERY_SCHEDULE_REQUEST, getDeliverySchedule, Api),
    takeLatest(Types.PAYMENT_METHOD_LIST_REQUEST, getPaymentMethods, Api),
    takeLatest(Types.PAYMENT_GATEWAY_SESSION_REQUEST, getPaymentGatewaySession, Api),
    takeLatest(Types.ADDRESS_LIST_REQUEST, getAddresses, Api),
    takeLatest(Types.CITY_LIST_REQUEST, getCities, Api),
    takeLatest(Types.NEIGHBORHOOD_LIST_REQUEST, getNeighborhoods, Api),
    takeLatest(Types.ADDRESS_REGISTER_REQUEST, registerAddress, Api),
    takeLatest(Types.ADDRESS_DELETE_REQUEST, deleteAddress, Api),
    takeLatest(Types.ADDRESS_UPDATE_REQUEST, updateAddress, Api),
    takeLatest(Types.ORDER_REGISTER_REQUEST, registerOrder, Api),
    takeLatest(Types.ORDER_DETAIL_REQUEST, getOrderDetail, Api),
    takeLatest(Types.ORDER_INSTALLMENT_REQUEST, setInstallmentOrder),
    takeLatest(Types.ORDER_LIST_REQUEST, getOrders, Api),
    takeLatest(Types.BANNER_LIST_REQUEST, getBanners, Api),
    takeLatest(Types.FEATURED_PRODUCT_LIST_REQUEST, getFeaturedProducts, Api),
    takeLatest(Types.ABOUT_US_REQUEST, getAboutUs, Api),
    takeLatest(Types.ACCOUNT_UPDATE_REQUEST, accountUpdate, Api),
    takeLatest(Types.FIND_ACCOUNT_REQUEST, findAccount, Api),
    takeLatest(Types.CART_GET_REMOTE_REQUEST, getCart, Api),
    takeLatest(Types.CART_UPDATE_REMOTE_REQUEST, updateCart, Api),
    takeLatest(Types.CART_ADD_SINGLE_REQUEST, addSingle, Api),
    takeLatest(Types.ORDER_WITHDRAWL_REGISTER_REQUEST, registerWithdrawlOrder, Api),
    takeLatest(Types.ORDER_PICPAY_DETAIL_REQUEST, getOrderPicpayDetail, Api),
    takeLatest(Types.LOGIN_GOOGLE_REQUEST, loginGoogle, Api),
    takeLatest(Types.LOGIN_FACEBOOK_REQUEST, loginFacebook, Api),
    takeLatest(Types.PRODUCT_SEARCH_REQUEST, searchProducts, SearchApi),
    takeLatest(Types.REGIONS_LIST_REQUEST, getRegionsDelivery, Api),
    takeLatest(Types.SEARCH_BY_CEP_REQUEST, getAddressByCep, Api),
  ])
}
