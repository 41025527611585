import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore } from '../redux/StartupRedux'
import { loggedToken, loggedUser } from '../redux/LoginRedux'
import { getFingerPrint } from '../redux/SearchRedux'

const storeId = ({ startup }) => loadedStore(startup)
const userToken = ({ login }) => loggedToken(login)
const userLogged = ({ login }) => loggedUser(login)
const fingerprintData = ({ search }) => getFingerPrint(search)

export function * getCart(api) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)

  api.setHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })

  const response = yield call(api.getCart)
  const { ok, data, problem } = response

  if (ok) {
    const { cart } = data
    const { products } = cart

    yield put(Creators.cartGetRemoteSuccess(products))
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.cartGetRemoteFailure(error))
  }
}

export function * updateCart(api, { products }) {
  const store_id = yield select(storeId)
  const token = yield select(userToken)

  api.setHeaders({
    Authorization: `Bearer ${token}`,
    'store-id': store_id
  })

  const response = yield call(api.updateCart, { products })
  const { ok, data, problem } = response

  if (ok) {
    yield put(Creators.cartUpdateRemoteSuccess())
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.cartUpdateRemoteFailure(error))
  }
}

export function * addSingle(api, { product_ean, origin }) {
  const store_id = yield select(storeId)
  const user = yield select(userLogged)
  const fingerprint = yield select(fingerprintData)
  
  api.setHeaders({
    'store-id': store_id
  })

  const response = yield call(api.addSingleToCart, { product_ean, origin, fingerprint, customer_id: user ? user.customer_id : null })
  const { ok, data, problem } = response

  if (ok) {
    yield put(Creators.cartAddSingleSuccess())
  } else {
    let error = problem
    if (data) {
      error = data.error
    }

    yield put(Creators.cartAddSingleFailure(error))
  }
}