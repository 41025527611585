import styled from 'styled-components'

export const ProductInfo = styled.div`
  width: calc(50% - 16px);
  display: flex;
  align-items: center;
  padding: 4px 8px;
  justify-content: ${({ end }) => !!end ? 'flex-end' : 'flex-start' };
  color: ${({ total }) => !!total ? '#707070' : ' #A8A8A8' };
`
export const ProductName = styled.div`
  width: calc(100% - 114px);
  padding: 8px;
  color: #707070;
`
export const ProductAction = styled.div`
  width: 30px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707070;
  &:last-child {
    padding-right: 8px;
  }
`
export const Label = styled.span`
  color: #707070;
  font-size: 13px;
  font-weight: ${({ bold }) => bold ? '700' : '400'};
  font-family: ${({ bold }) => bold ? 'Gilmer-Bold' : 'Gilmer'};
  padding: 4px 8px;
`
export const Divider = styled.div`
  border: 1px solid #ccc;
  margin-top: 5px;
`
