import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  email: null,
  password: null,
  user: null,
  storeRedirect: true,
  storeRedirectExpireIn: null,
  validResetToken: null,
  showLoginDialog: false,
  redirectStoreUrl: null,
})

export const loginRequest = (state = INITIAL_STATE, { email, password }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    email,
    password,
    user: null
  })

export const loginSuccess = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    email: null,
    password: null,
    user: user
  })

export const loginFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error: error,
    email: null,
    password: null,
    user: null,
    validResetToken: null,
  })

export const recoverPasswordRequest = (state = INITIAL_STATE, { email }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false
  })

export const recoverPasswordSuccess = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: false,
    error: false
  })

export const recoverPasswordFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error
  })

export const validateResetPasswordTokenRequest = (state = INITIAL_STATE, { token }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    validResetToken: null
  })

export const validateResetPasswordTokenSuccess = (state = INITIAL_STATE, { valid }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    validResetToken: valid
  })

export const validateResetPasswordTokenFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    validResetToken: null,
    error
  })

export const resetPasswordRequest = (state = INITIAL_STATE, { token, password }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false
  })

export const resetPasswordSuccess = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: false,
    error: false
  })

export const resetPasswordFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error
  })

export const refreshTokenRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false
  })

export const refreshTokenSuccess = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    user
  })

export const refreshTokenFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error: null,
    user: null
  })

export const logoutRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    email: null,
    password: null,
    user: null
  })

export const registerRequest = (state = INITIAL_STATE, { firstname, lastname, telephone, email, password }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    user: null
  })

export const registerSuccess = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    user: user
  })

export const registerFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    error: error,
    user: null
  })

export const setStoreRedirect = (state = INITIAL_STATE, { status, expireIn }) =>
  Object.assign({}, state, {
    storeRedirect: status,
    storeRedirectExpireIn: expireIn
  })

export const setUserRequest = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    user
  })

export const setLoginDialogRequest = (state = INITIAL_STATE, { show }) =>
  Object.assign({}, state, {
    showLoginDialog: show
  })

export const loginGoogleRequest = (state = INITIAL_STATE, { code }) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
  })
export const loginGoogleSuccess = (state = INITIAL_STATE, { user }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    user: user,
  })

export const loginFacebookRequest = (state = INITIAL_STATE, { code }) => 
  Object.assign({}, state, {
    fetching: true,
    error: null
  })

export const loginFacebookSuccess = (state = INITIAL_STATE, { user }) => 
  Object.assign({}, state, {
    fetching: false,
    error: null,
    user: user
  })

export const searchByCepRequest = (state = INITIAL_STATE, { postcode }) => 
  Object.assign({}, state, {
    fetching: true,
    error: null,
    redirectStoreUrl: null
  }) 

export const searchByCepSuccess = (state = INITIAL_STATE, { groups }) => 
  Object.assign({}, state, {
    fetching: false,
    error: null,
    redirectStoreUrl: groups
  }) 
export const searchByCepFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    redirectStoreUrl: null,
    error
  })

  /**
 * Handlers
 */

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.RECOVER_PASSWORD_REQUEST]: recoverPasswordRequest,
  [Types.RECOVER_PASSWORD_SUCCESS]: recoverPasswordSuccess,
  [Types.RECOVER_PASSWORD_FAILURE]: recoverPasswordFailure,

  [Types.VALIDATE_RESET_PASSWORD_TOKEN_REQUEST]: validateResetPasswordTokenRequest,
  [Types.VALIDATE_RESET_PASSWORD_TOKEN_SUCCESS]: validateResetPasswordTokenSuccess,
  [Types.VALIDATE_RESET_PASSWORD_TOKEN_FAILURE]: validateResetPasswordTokenFailure,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  [Types.REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
  [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
  [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure,

  [Types.REGISTER_REQUEST]: registerRequest,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAILURE]: registerFailure,

  [Types.LOGOUT_REQUEST]: logoutRequest,

  [Types.SET_STORE_REDIRECT]: setStoreRedirect,
  [Types.SET_USER_REQUEST]: setUserRequest,
  [Types.SET_LOGIN_DIALOG_REQUEST]: setLoginDialogRequest,

  [Types.LOGIN_GOOGLE_REQUEST]: loginGoogleRequest,
  [Types.LOGIN_GOOGLE_SUCCESS]: loginGoogleSuccess,

  [Types.LOGIN_FACEBOOK_REQUEST]: loginFacebookRequest,
  [Types.LOGIN_FACEBOOK_SUCCESS]: loginFacebookSuccess,

  [Types.SEARCH_BY_CEP_REQUEST]: searchByCepRequest,
  [Types.SEARCH_BY_CEP_SUCCESS]: searchByCepSuccess,
  [Types.SEARCH_BY_CEP_FAILURE]: searchByCepFailure,

}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer

export const isLogged = loginState =>
  loginState.user !== null

export const loggedToken = loginState => loginState.user.accessToken

export const loggedUser = loginState => loginState.user
