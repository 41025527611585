import { call, put, select } from 'redux-saga/effects'
import { Creators } from '../actions'
import { loadedStore } from '../redux/StartupRedux'

const storeId = ({ startup }) => loadedStore(startup)

export function * getDeliveries(api, { neighborhood_id }) {
  const store_id = yield select(storeId)
  api.setHeaders({
    'store-id': store_id
  })
  
  const response = yield call(api.getDeliveries, { neighborhood_id })
  const { ok, data } = response

  if (ok) {
    const { deliveries } = data

    yield put(Creators.deliveryListSuccess(deliveries))
  } else {
    const { error } = data

    yield put(Creators.deliveryListFailure(error))
  }
}

export function * getDeliverySchedule(api) {
  const store_id = yield select(storeId)
  api.setHeaders({
    'store-id': store_id
  })
  
  const response = yield call(api.getDeliverySchedule)
  const { ok, data } = response

  if (ok) {
    const { schedule } = data

    yield put(Creators.deliveryScheduleSuccess(schedule))
  } else {
    const { error } = data

    yield put(Creators.deliveryScheduleFailure(error))
  }
}

export function * getCities(api) {
  const store_id = yield select(storeId)
  api.setHeaders({
    'store-id': store_id
  })
  
  const response = yield call(api.getCities)
  const { ok, data } = response

  if (ok) {
    const { cities } = data

    yield put(Creators.cityListSuccess(cities))
  } else {
    const { error } = data

    yield put(Creators.cityListFailure(error))
  }
}

export function * getNeighborhoods(api) {
  const store_id = yield select(storeId)
  api.setHeaders({
    'store-id': store_id
  })
  
  const response = yield call(api.getNeighborhoods)
  const { ok, data } = response

  if (ok) {
    const { neighborhoods } = data

    yield put(Creators.neighborhoodListSuccess(neighborhoods))
  } else {
    const { error } = data

    yield put(Creators.neighborhoodListFailure(error))
  }
}
export function * getRegionsDelivery(api, { neighborhood_id }) {
  const store_id = yield select(storeId)
  api.setHeaders({
    'store-id': store_id
  })
  
  const response = yield call(api.getRegionsDelivery, { neighborhood_id })
  const { ok, data } = response

  if (ok) {
    const { regions } = data

    yield put(Creators.regionsListSuccess(regions))
  } else {
    const { error } = data

    yield put(Creators.deliveryListFailure(error))
  }
}
