import { createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { Types } from '../actions'

export const INITIAL_STATE = Immutable({
  fetching: false,
  error: false,
  store: null
})

export const startupRequest = (state = INITIAL_STATE) =>
  Object.assign({}, state, {
    fetching: true,
    error: false,
    store: null
  })

export const startupSuccess = (state = INITIAL_STATE, { store }) =>
  Object.assign({}, state, {
    fetching: false,
    error: false,
    store
  })

export const startupFailure = (state = INITIAL_STATE, { error }) =>
  Object.assign({}, state, {
    fetching: false,
    store: null,
    error
  })

/**
 * Handlers
 */

export const HANDLERS = {
  [Types.STARTUP_REQUEST]: startupRequest,
  [Types.STARTUP_SUCCESS]: startupSuccess,
  [Types.STARTUP_FAILURE]: startupFailure
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)

export default reducer

export const loadedStore = startupState => startupState.store.store_id
export const storeConfig = startupState => startupState.store ? startupState.store.config : null

export const getStoreData = startupState => startupState.store || null
